import React, { FunctionComponent, MouseEventHandler } from 'react';
import { Checkbox } from '../../inputs/checkbox/checkbox.component';
import { BaseButton, BaseButtonProps } from '../base-button/base-button.component';

export type CheckboxButtonProps = {
	isChecked: boolean;
	hasBorder?: boolean;
	label: string | React.ReactElement;
	onClick: MouseEventHandler<HTMLButtonElement>;
	value: string;
	id: string;
} & Omit<BaseButtonProps, 'onClick'>;

/**
 * Renders a checkbox in a button.  This pattern is not something we want to promote however we need for parity.  The parent controls the
 * state of the checkbox button and is responsible for providing an onClick that toggles the state, similar to a controlled input
 */
export const CheckboxButton: FunctionComponent<CheckboxButtonProps> = ({
	isChecked,
	hasBorder,
	label,
	onClick: onClickProp,
	size = 'DEFAULT',
	value,
	className = '',
	id,
	...remainingProps
}) => {
	// we want the button to prevent the click from interacting with the checkbox, as the checkbox is completely controlled by the parent
	const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		onClickProp(e);
	};

	const additionalClassName = className ? ` ${className}` : '';

	return (
		<BaseButton
			onClick={onClick}
			className={`z-1 theme-grey-darker bg-theme-white ${
				hasBorder ? 'hover-theme-primary b--theme-grey-darker hover-b--theme-primary' : 'bw0'
			}${additionalClassName}`}
			size={size}
			{...remainingProps}>
			<Checkbox id={id} value={value} name={value} label={label} isChecked={isChecked} tabIndex={-1} readOnly={true} />
		</BaseButton>
	);
};
