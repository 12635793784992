/**
 * Functions responsible for returning GTM event payloads.
 * Function names should start with "build" and should return an event type
 */
import { CustomerTypeEnum } from '../../../__generated__/graphql-client-types';
import { CurrencyCode } from '../../../constants/currency';
import { DYAnalyticsData } from '../../../types/analytics.types';
import { CheckoutOrderReceiptResult } from '../../../types/checkout.types';
import { ShowroomBookApptEvent, ShowroomBookingStepNames, ShowroomBookingSteps } from '../../../types/showroom.types';
import { EventAction } from '../action-types';
import { AddToCartProductFamily } from '../add-to-cart-analytics.helper';
import { TrackedEvent, TrackedEventCase } from '../event-types';
import {
	AllGTMEvents,
	GTMAddToCartEvent,
	GTMCartSummary,
	GTMCustomEvent,
	GTMCustomProductDimensions,
	GTMCustomerLoginEvent,
	GTMDYVariationImpressionEvent,
	GTMEventWrapper,
	GTMPageData,
	GTMProduct,
	GTMRemoveFromCartEvent,
	GTMShowroomBookingEvent
} from './gtm-types';
import { CartMutationResultCart, buildGTMProduct, generateCartSummary, buildGTMCartProduct, wrapGTMEvent } from './gtm-utils.helper';

/**
 * returns the GTM page-view event payload
 */
export const buildGTMPageViewData = (pageData: GTMPageData): GTMCustomEvent => {
	const { page, baseCategory = '', businessCategory = '', brand = '', customer, employee, collection = '' } = pageData;
	/* These properties must exist on the object, even if they are undefined */
	const eventData: GTMCustomEvent = {
		event: TrackedEvent.PAGE_VIEW,
		page,
		baseCategory,
		brand,
		businessCategory,
		customer,
		employee,
		collection
	};
	return eventData;
};

/**
 * Convenience method to build GTM data for add-to-cart event types.
 * Note that it only accepts domain/primitive types so
 * that client code need not 'know' about GTM-specific models.
 */
export const buildGTMAddToCartEvent = (
	useCase: TrackedEventCase,
	variantId: number,
	quantity: number,
	productFamily: AddToCartProductFamily,
	cartMutationResult: CartMutationResultCart,
	recommendedType?: string
): GTMEventWrapper<GTMAddToCartEvent> => {
	const addedProduct = buildGTMProduct(
		productFamily,
		productFamily.variants.find((variant) => variant.id === variantId) || productFamily.variants[0],
		quantity
	);

	const cartSummary = generateCartSummary(cartMutationResult);

	return buildGTMAddToCart(useCase, [addedProduct], cartSummary, undefined, recommendedType);
};

/**
 * Builds an add to cart event for GTM
 */
export const buildGTMAddToCart = (
	useCase: TrackedEventCase,
	gtmProducts: GTMProduct[],
	cartSummary: GTMCartSummary,
	currencyCode = CurrencyCode.UNITED_STATES,
	recommendedType?: string
): GTMEventWrapper<GTMAddToCartEvent> => {
	// Shim in useCase into the product dimension data due to reporting limitations in GA
	gtmProducts[0][GTMCustomProductDimensions.ADD_TO_CART_METHOD] = useCase;
	return wrapGTMEvent(
		TrackedEvent.ADD_TO_CART,
		useCase,
		{
			currencyCode,
			add: {
				products: gtmProducts
			},
			cartSummary
		},
		undefined,
		recommendedType
	);
};

/**
 * Builds a remove from cart event for GTM
 */
export const buildGTMRemoveFromCart = (
	useCase: TrackedEventCase,
	gtmProducts: GTMProduct[],
	cartSummary: GTMCartSummary
): GTMEventWrapper<GTMRemoveFromCartEvent> => {
	return wrapGTMEvent(TrackedEvent.REMOVE_FROM_CART, useCase, {
		remove: {
			products: gtmProducts
		},
		cartSummary
	});
};

/**
 * Helper function to generate the appropriate tracking event for item updates
 * from the cart page.
 *
 * @param {number} updatedQuanity
 * @param {*} cartItem
 * @param {CartMutationResultCart} cart
 */
export const buildGTMUpdateCartEvent = (
	updatedQuanity: number,
	cartItem,
	cart: CartMutationResultCart
): GTMEventWrapper<GTMAddToCartEvent> | GTMEventWrapper<GTMRemoveFromCartEvent> => {
	const updateAmount = Math.abs(updatedQuanity);
	const updatedItem: GTMProduct = buildGTMCartProduct(cartItem, updateAmount);
	const cartSummary = generateCartSummary(cart);
	if (updatedQuanity > 0) {
		return buildGTMAddToCart(TrackedEventCase.ADD_TO_CART_CART_PAGE, [updatedItem], cartSummary);
	} else {
		return buildGTMRemoveFromCart(TrackedEventCase.ADD_TO_CART_CART_PAGE, [updatedItem], cartSummary);
	}
};

export const buildGTMOrderComplete = (
	receipt: CheckoutOrderReceiptResult,
	isPro: boolean,
	userIP?: string
): GTMEventWrapper<AllGTMEvents> => {
	const ip = userIP ? userIP : '';
	const purchase = {
		customer: {
			ip,
			proBusinessType: isPro,
			fullName: '',
			phoneNumber: '',
			email: '',
			zip: ''
		}
	};
	if (receipt?.__typename === 'OrderReceipt') {
		let inHomeDelivery = false;
		const items = receipt.orderedItems;
		const productsData = items
			? items.map((orderedItem) => {
					const {
						uniqueId,
						baseCategory,
						stockCount,
						image,
						manufacturer,
						price,
						quantity,
						collection,
						type,
						application,
						productId,
						whiteGloveSelected
					} = orderedItem;

					if (whiteGloveSelected) {
						inHomeDelivery = true;
					}
					return {
						uniqueId,
						baseCategory,
						stockCount,
						finishName: image.description,
						manufacturer,
						price,
						quantity,
						collection,
						type,
						application,
						image: image.id,
						productId
					};
			  })
			: null;
		purchase.customer.fullName = receipt.billingAddress?.fullName;
		purchase.customer.phoneNumber = receipt.billingAddress?.phoneNumber;
		purchase.customer.email = receipt.email;
		purchase.customer.zip = receipt.billingAddress?.zipCode;
		purchase['products'] = productsData;

		const { couponCode, id, subTotal, taxTotal, shippingTotal, isLTL } = receipt;

		let finalShippingMethod = 'Mixed';

		if (isLTL && !inHomeDelivery) {
			finalShippingMethod = 'Freight (LTL)';
		} else if (!isLTL && !inHomeDelivery) {
			finalShippingMethod = 'Standard Delivery';
		}
		const actionField = {
			action: EventAction.GTM_PURCHASE_ACTION,
			coupon: couponCode,
			id,
			revenue: subTotal,
			tax: taxTotal,
			shipping: shippingTotal,
			shippingMethod: finalShippingMethod
		};

		purchase['actionField'] = actionField;
	}
	return {
		event: TrackedEvent.ORDER_COMPLETE,
		ecommerce: {
			purchase
		}
	};
};

export const buildGTMCustomerLoginEvent = (
	customerType: CustomerTypeEnum,
	isNewAccount: boolean,
	userDBID: number
): GTMCustomerLoginEvent => {
	return {
		event: TrackedEvent.CUSTOMER_LOGIN,
		customerType,
		isNewAccount,
		userDBID
	};
};

export const buildGTMDYVariationImpressionEvent = (dyAnalyticsData: DYAnalyticsData): GTMDYVariationImpressionEvent => {
	const { campaignName, experienceName, variationName } = dyAnalyticsData;
	return {
		event: TrackedEvent.DY_EVENT,
		eventAction: campaignName,
		eventCategory: 'DY Smart Object',
		eventLabel: `${experienceName} (${variationName})`
	};
};

export const buildGTMShowroomBookingEvent = (
	flow: 'cancel' | 'do-not-cancel' | 'flow' | 'login',
	event?: ShowroomBookApptEvent
): GTMShowroomBookingEvent => {
	if (flow === 'cancel') {
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step: 'cancel-booking-flow',
			step_name: TrackedEventCase.CANCELLED_BOOKING_FLOW
		};
	} else if (flow === 'do-not-cancel') {
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step: 'return-to-booking-flow',
			step_name: TrackedEventCase.RETURN_TO_BOOKING_FLOW
		};
	} else if (flow === 'login') {
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step: flow,
			step_name: TrackedEventCase.PLEASE_LOGIN_TO_CONTINUE
		};
	} else {
		let step;
		let step_name;
		if (event) {
			const { type, detail, bookingDetail } = event;
			// the salesforce implementation sends a separate event labeled "gtm" with booking detail
			// to prevent sending two "review" events for an individual booking, we only send the review stage with the booking detail
			if (type === 'booking-id' && bookingDetail) {
				return {
					event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
					step: ShowroomBookingSteps['review'],
					step_name: ShowroomBookingStepNames['review'],
					bookingDetail
				};
			} else if (type === 'stage' && detail !== 'review') {
				// will leave "review" event as undefined
				step = ShowroomBookingSteps[detail];
				step_name = ShowroomBookingStepNames[detail];
			} else if (type === 'finish' || type === 'cancel') {
				step = type;
				step_name = type === 'finish' ? TrackedEventCase.FINISH : TrackedEventCase.ATTEMPT_TO_CANCEL;
			}
		}
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step,
			step_name
		};
	}
};
