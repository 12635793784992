import { ApolloQueryResult, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { logError } from 'fergy-core-react-logging';
import {
	Maybe,
	ZipCodeSourceEnum,
	CustomerQuery,
	CustomerQueryVariables,
	SetCustomerLocationMutation,
	SetCustomerLocationMutationVariables,
	LocationFieldsFragment,
	LocationQuery,
	LocationQueryVariables
} from '../../../__generated__/graphql-client-types';
import { CUSTOMER, LOCATION, SET_CUSTOMER_LOCATION } from '../../../queries/customer/customer.queries';

export type UseCustomerPayload = {
	data: CustomerQuery | undefined;
	previousData: CustomerQuery | undefined;
	loading: boolean;
	hasAccount: boolean;
	isPro: boolean;
	refetch: (variables?: Partial<CustomerQueryVariables>) => Promise<ApolloQueryResult<CustomerQuery>>;
};
export const useCustomer = (): UseCustomerPayload => {
	const { data, previousData, loading, error, refetch } = useQuery<CustomerQuery, CustomerQueryVariables>(CUSTOMER, {
		fetchPolicy: 'cache-first' // given how often we use customer data, avoid re-renders caused by cache-and-network
	});

	if (error) {
		logError(error);
		return { data, previousData, loading, hasAccount: false, isPro: false, refetch };
	}

	let hasAccount = false;
	let isPro = false;
	const customer = data?.customer;
	if (customer) {
		hasAccount = customer.profile.type !== 'GUEST';
		isPro = customer.profile.type === 'PRO';
	}

	return { data, previousData, loading, hasAccount, isPro, refetch };
};

export const useSetCustomerLocation = () => {
	const [setCustomerLocation] = useMutation<SetCustomerLocationMutation, SetCustomerLocationMutationVariables>(SET_CUSTOMER_LOCATION);

	return (zipCode: string, source: ZipCodeSourceEnum): Promise<any> => {
		return setCustomerLocation({ variables: { zipCode, source } })
			.then((result) => {
				if (result?.errors?.length) {
					return Promise.reject(result.errors[0].message);
				}
				return Promise.resolve(result);
			})
			.catch((error) => {
				logError(error);
				return Promise.reject(error);
			});
	};
};

export const useUpdateCustomerLocationCache = () => {
	const client = useApolloClient();

	return {
		updateCustomerLocationCache: (location?: Maybe<LocationFieldsFragment>) => {
			if (!location) {
				return;
			}

			const customerData = client.readQuery<CustomerQuery>({
				query: CUSTOMER
			});

			if (customerData) {
				client.writeQuery({
					query: CUSTOMER,
					data: {
						customer: {
							...customerData.customer,
							location: { ...location, id: customerData.customer.location.id }
						}
					}
				});
			}
		}
	};
};

export const useUpdateCustomerLocationCacheByZipCode = () => {
	const [getLocation] = useLazyQuery<LocationQuery, LocationQueryVariables>(LOCATION);
	const { updateCustomerLocationCache } = useUpdateCustomerLocationCache();

	return {
		updateCustomerLocationCacheByZipCode: (zipCode: string) => {
			return getLocation({
				variables: {
					zipCode
				}
			})
				.then((res) => updateCustomerLocationCache(res.data?.location))
				.catch(logError);
		}
	};
};
