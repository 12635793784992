import { logError } from 'fergy-core-react-logging';
import React, { FunctionComponent, SyntheticEvent, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { ACCEPTABLE_DELAY, DOHERTY_THRESHOLD, FEATURE_FLAGS } from '../../../constants/general';
import { SHOP_ALL_CATEGORIES_LINK } from '../../../constants/links';
import { AnalyticsHelper } from '../../../helpers/analytics/analytics.helper';
import { buildGTMHeaderCategoryLevelOneClick, buildGTMHeaderCategoryLevelTwoClick } from '../../../helpers/analytics/gtm/gtm-utils.helper';
import { useFeature } from '../../../hooks/features/features.hooks';
import { useNavigation } from '../../../hooks/navigation/navigation.hooks';
import { ClickableElement } from '../../buttons';
import { Link } from '../../common-components/link/link.component';
import { Overlay } from '../../common-components/overlay/overlay.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { ArrowRightAltIcon } from '../../svg/icons.component';
import { useSiteInfo } from '../../../hooks/apollo/site/site.hooks';
import { navHoverIndicator } from './desktop-nav.css';

const desktopNavMenuClassName =
	'absolute left-0 right-0 mw9 tc center w-100 bg-theme-white flex flex-row justify-between items-start pa3 z-4';

export const DesktopNav: FunctionComponent = () => {
	const {
		data: { navigation }
	} = useSiteInfo(6);
	const [showMenu, setShowMenu] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
	// this prevents flashing while the user sweeps across the menu items while the nav is open
	const [debouncedSelectedIndex] = useDebounce(selectedIndex, ACCEPTABLE_DELAY);
	const isMainNavigationUpdatesDesktopActive = useFeature(FEATURE_FLAGS.MAIN_NAVIGATION_UPDATES_DESKTOP);
	// delay closing the menu to see if mouse has moved somewhere where it should remain open
	const closeMenuTimer = useRef<ReturnType<typeof setTimeout>>();
	const openMenuTimer = useRef<ReturnType<typeof setTimeout>>();
	const clearMenuTimeouts = () => {
		if (openMenuTimer.current) {
			clearTimeout(openMenuTimer.current);
			openMenuTimer.current = undefined;
		}
		if (closeMenuTimer.current) {
			clearTimeout(closeMenuTimer.current);
			closeMenuTimer.current = undefined;
		}
	};

	const closeMenu = () => {
		clearMenuTimeouts();
		closeMenuTimer.current = setTimeout(() => {
			setShowMenu(false);
		}, DOHERTY_THRESHOLD);
	};

	const openMenu = (useDelay = true) => {
		clearMenuTimeouts();
		if (useDelay) {
			openMenuTimer.current = setTimeout(() => setShowMenu(true), DOHERTY_THRESHOLD);
		} else {
			setShowMenu(true);
		}
	};
	// "nav" event handlers for our top level categories
	const handleNavMouseEnter = (e: SyntheticEvent<HTMLDivElement>) => {
		setSelectedIndex(Number(e?.currentTarget?.dataset?.index));
		openMenu();
	};

	const handleNavTouch = (e: SyntheticEvent<HTMLDivElement>) => {
		setSelectedIndex(Number(e?.currentTarget?.dataset?.index));
		openMenu(false);
		// prevent click event from firing (touch devices will also fire click)
		// Note: we need to use touchEnd here, as React registers touchStart as a passive event listener
		// so browsers won't detect a preventDefault
		e.preventDefault();
	};

	// "menu" event handlers for child categories in the popup menu
	const handleMenuMouseEnter = () => {
		openMenu();
	};

	const navigate = useNavigation();

	const handleShopAllClick = () => {
		AnalyticsHelper.track(
			buildGTMHeaderCategoryLevelOneClick(isMainNavigationUpdatesDesktopActive ? 'All Departments' : 'Shop All Departments')
		).catch(logError);
		closeMenu();
		navigate(SHOP_ALL_CATEGORIES_LINK);
	};

	return (
		<>
			{showMenu && <Overlay style="dark" hasTransparentHeader={true} onClick={closeMenu} className={'fade-in'} />}
			<nav
				role="navigation"
				className={`dn db-ns relative bg-theme-secondary bb b--theme-primary bw2 ${showMenu ? 'z-3' : ''} omniHome-navBar-bg`}
				aria-label="departments">
				<div className="mw9 center flex flex-row items-center justify-between">
					{isMainNavigationUpdatesDesktopActive ? (
						<div
							onMouseEnter={handleNavMouseEnter}
							onTouchEnd={handleNavTouch}
							onMouseLeave={closeMenu}
							// setting this to 100 to avoid conflicts in case the nav categories changes in the future
							data-index={100}
							data-testid="all-departments"
							className={showMenu ? 'z-4 relative' : ''}>
							<ClickableElement
								ariaLabel="All Departments"
								className={`pa3 theme-white ${
									showMenu && debouncedSelectedIndex === 100 ? `${navHoverIndicator} bg-theme-black` : ''
								}`}
								automationHook="All Departments"
								onClick={handleShopAllClick}>
								All Departments
							</ClickableElement>
						</div>
					) : (
						<ClickableElement
							ariaLabel="Shop All Departments"
							className={`pa3 bg-theme-black theme-white ${showMenu ? 'z-4' : ''} omnniHome-shopAllDepartments-bg`}
							automationHook="Shop All Departments"
							onClick={handleShopAllClick}>
							Shop All Departments
						</ClickableElement>
					)}
					{navigation.map((category, index) => {
						const selectedIndicator = debouncedSelectedIndex === index ? `${navHoverIndicator} bg-theme-black` : '';
						const showMenuClass = showMenu ? `z-4 ${selectedIndicator}` : '';
						const isRendered = index < 10 || (!isMainNavigationUpdatesDesktopActive && category.name === 'Clearance');
						/**
						 * We want to show:
						 *  - The first 5 categories + clearance for tablet
						 *  - The first 10 categories + clearance for desktop
						 *
						 * We achieve this by only rendering the first 10
						 * categories and the clearance category and then
						 * adding a class to apply display: none for medium
						 * devices to categories 6-10
						 */
						return (
							isRendered && (
								<div
									key={category.id}
									data-testid="DesktopNavItem"
									data-index={index}
									onMouseEnter={handleNavMouseEnter}
									onTouchEnd={handleNavTouch}
									onMouseLeave={closeMenu}
									className={`${index > 5 && category.name !== 'Clearance' ? 'dn-m' : ''}`}>
									<Link
										color="white"
										className={`relative pv3 ph2 ${showMenuClass}`}
										url={category.url}
										automationHook={category.name}
										analyticsEvent={{ data: buildGTMHeaderCategoryLevelOneClick(category.name) }}>
										{category.name}
									</Link>
								</div>
							)
						);
					})}
				</div>
			</nav>
			{showMenu && (
				<div
					data-testid="DesktopNavMenu"
					onMouseEnter={handleMenuMouseEnter}
					onMouseLeave={closeMenu}
					onTouchEnd={closeMenu}
					className={desktopNavMenuClassName}>
					{isMainNavigationUpdatesDesktopActive && debouncedSelectedIndex === 100 ? (
						<div className="flex flex-wrap justify-even ga4 ma3">
							{navigation.slice(0, 10).map((category) => (
								<div key={category.id} className="flex flex-column items-start" style={{ width: '17%' }}>
									<Link
										url={category.url}
										color="black"
										className="b f6 lh-solid mb1"
										ariaLabel={category.name}
										underline={false}
										underlineHover={true}>
										{category.name}
									</Link>
									{category.subCategories?.slice(0, 5).map((subcat) => (
										<div key={subcat.id} className="f6 lh-solid fw4 mv1 ">
											<Link
												url={subcat.url}
												ariaLabel={subcat.name}
												color="black"
												className="flex tl"
												underline={false}
												underlineHover={true}>
												{subcat.name}
											</Link>
										</div>
									))}
									<div className="f6 lh-solid fw4 mv1">
										<Link url={category.url} color="black" className="flex tl" underline={false} underlineHover={true}>
											{`All ${category.name}`}
										</Link>
									</div>
								</div>
							))}
						</div>
					) : (
						<>
							{navigation[debouncedSelectedIndex].subCategories?.map((subcat) => (
								<Link
									url={subcat.url}
									key={subcat.id}
									color={'grey-darker'}
									underlineHover={true}
									textAlign={'tc'}
									className="link w5 f5 fw5 pa2 lh-title"
									automationHook={subcat.name}
									analyticsEvent={{ data: buildGTMHeaderCategoryLevelTwoClick(subcat.name) }}>
									<CloudinaryImage
										publicID={subcat.image.id}
										description={subcat.image.description}
										options={{ width: 140, height: 140 }}
									/>
									<div>{subcat.name}</div>
								</Link>
							))}
							<Link
								url={navigation[debouncedSelectedIndex].url}
								color={isMainNavigationUpdatesDesktopActive ? 'primary' : 'grey-darker'}
								underlineHover={true}
								textAlign={'tc'}
								className="link w5 f5 fw5 pa2 lh-title"
								automationHook={`Shop All ${navigation[debouncedSelectedIndex].name}`}
								analyticsEvent={{
									data: buildGTMHeaderCategoryLevelTwoClick(`Shop All ${navigation[debouncedSelectedIndex].name}`)
								}}>
								{isMainNavigationUpdatesDesktopActive ? (
									<div className="flex justify-center items-center" style={{ height: 170 }}>
										<span>All {navigation[debouncedSelectedIndex].name}</span>
										<ArrowRightAltIcon className="theme-primary f6 ml1" />
									</div>
								) : (
									<>
										<CloudinaryImage
											publicID={navigation[debouncedSelectedIndex].image.id}
											description={navigation[debouncedSelectedIndex].image.description}
											options={{ width: 140, height: 140 }}
										/>
										<div>Shop All {navigation[debouncedSelectedIndex].name}</div>
									</>
								)}
							</Link>
						</>
					)}
				</div>
			)}
		</>
	);
};
