/**
 * Site wide client message constants.  Domain specific messages should go in their
 * own message file, cart/message, product/message etc.
 *
 * As compared to server side message catalog {@see src/server/message-catalog.ts},
 * messages should be declared not as enums but as single entries instead, to take advantage of
 * tree-shaking and reduce bundle size for each instance of use.
 */
import { DEFAULT_PHONE } from './general';

export const GENERIC_ERROR = `A problem occurred, please try again later or contact us at ${DEFAULT_PHONE}`;

export const SUCCESS_SUBSCRIBED = 'Thanks for Subscribing!';
export const ERROR_SUBSCRIBE = 'An error has occurred while subscribing to the newsletter.';

export const SUCCESS_ZIP_CODE_CHANGED = 'Zip code applied';

export const NO_SPECIAL_CHARACTERS = 'Sorry, we do not allow special characters';
export const VALID_NAME_REQUIRED = 'Please enter a valid name.';
export const NO_ASTERISK = 'Sorry, no asterisks allowed in Full Name.';
export const FULL_NAME_REQUIRED = 'Please enter your full name.';

export const ITEM_ADDED_TO_CART = 'Item(s) added to cart';
export const ITEM_ADDED_TO_CART_FAIL = 'Failed to add item(s) to cart';
export const ITEM_ADDED_TO_CART_INELIGIBLE = 'None of the selected products are eligible to be added to cart';

export const CARD_EXPIRED = 'The selected credit card is expired. Please update or select another card.';

export const INELIGIBLE_COMPLEX_ORDER_ITEMS =
	'Some items in your cart are not eligible for Complex Orders. Please remove these item(s) to proceed.';
