
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddProductsToCartResult": [
      "AddProductsToCart",
      "AddProductsToCartError"
    ],
    "AddProductsToProjectResult": [
      "AddProductsToCartError",
      "AddProductsToProject"
    ],
    "AddressResult": [
      "Address",
      "Error"
    ],
    "AmazonPaymentResult": [
      "AmazonPayment",
      "Error"
    ],
    "AmendOrderResult": [
      "AmendOrder",
      "Error"
    ],
    "ApplianceProduct": [
      "ApplianceRecommendation",
      "ApplianceRecommendedProduct"
    ],
    "ApplyEmployeeDiscountResult": [
      "ApplyEmployeeDiscount",
      "Error"
    ],
    "AuthorizePaymentResult": [
      "AuthorizePayment",
      "Error"
    ],
    "BidReviewProductData": [
      "BidReviewBuildProductData",
      "BidReviewSpecialProductData"
    ],
    "CallToAction": [
      "Link",
      "VideoLink"
    ],
    "CancelOrderPageResult": [
      "CancelOrderPage",
      "Error"
    ],
    "CancelOrderResult": [
      "CancelOrder",
      "Error"
    ],
    "CancelReturnResult": [
      "CancelReturn",
      "Error"
    ],
    "CarouselItem": [
      "HomePageCarouselItem",
      "HomePageExclusiveDealsItem",
      "HomePageSaleCategoriesItem",
      "HomePageShopByLookSlide"
    ],
    "CartResult": [
      "Cart",
      "Error"
    ],
    "CategoryResult": [
      "Category",
      "NotFoundError"
    ],
    "CheckoutOrderReceipt": [
      "Error",
      "OrderReceipt"
    ],
    "CheckoutResult": [
      "Checkout",
      "Error"
    ],
    "CloneProjectResult": [
      "CloneProject",
      "Error"
    ],
    "ComplexOrderProductsEligibleResult": [
      "ComplexOrderProductsEligible",
      "Error"
    ],
    "ContentCard": [
      "ArticleCard",
      "CategoryCard",
      "FavoriteCard"
    ],
    "ContentCardDetail": [
      "ContentArticleCardDetail",
      "ContentBrandCardDetail",
      "ContentCategoryCardDetail",
      "ContentFavoriteCardDetail",
      "ContentProductCardDetail",
      "ContentProfileCardDetail",
      "ContentVideoCardDetail"
    ],
    "ContentGeneralItems": [
      "ContentCallToAction",
      "ContentRichText",
      "GeneralGroupContent",
      "Image",
      "Video"
    ],
    "ContentGroupItem": [
      "ArticleCard",
      "CategoryCard",
      "FavoriteCard",
      "Image",
      "Video"
    ],
    "ContentResult": [
      "CategoryDropPageContent",
      "CategoryPageContent"
    ],
    "ContentValue": [
      "ContentCardValue",
      "ContentImageValue",
      "ContentLinkValue",
      "ContentListValue",
      "ContentObjectValue",
      "ContentRichTextValue",
      "ContentTextValue"
    ],
    "CreateAccountResult": [
      "AuthenticatedCustomer",
      "Error"
    ],
    "CreateTrilogieBidResult": [
      "CreateTrilogieBid",
      "Error"
    ],
    "CreditCardResult": [
      "CreditCard",
      "Error"
    ],
    "CustomerLoginResult": [
      "AuthenticatedCustomer",
      "AuthenticationFailure"
    ],
    "CustomerQuotesResult": [
      "CustomerQuotes",
      "Error"
    ],
    "DeleteCartItemResult": [
      "Cart",
      "Error",
      "SaveForLaterCart"
    ],
    "EmailCartResult": [
      "EmailCart",
      "Error"
    ],
    "EnrollInSmsResult": [
      "EnrollInSms",
      "Error"
    ],
    "GeneralContentItem": [
      "GeneralImageItem",
      "GeneralVideoItem"
    ],
    "GroupSelection": [
      "RecommendedGroupSelection",
      "RequiredGroupSelection",
      "VariationGroupSelection"
    ],
    "HomePageSection": [
      "DynamicYieldTemplate",
      "HomePageBanner",
      "HomePageCarousel",
      "HomePageExclusiveDeals",
      "HomePageFeaturedBrands",
      "HomePageInspiration",
      "HomePageProject",
      "HomePageShopByLook"
    ],
    "Identity": [
      "DynamicYieldTemplate",
      "HomePage",
      "HomePageBanner",
      "HomePageCarousel",
      "HomePageFeaturedBrands",
      "HomePageInspiration",
      "HomePageProject",
      "HomePageSaleCategories",
      "HomePageShopByLook",
      "HomePageSplash"
    ],
    "ImportBidToProjectResult": [
      "Error",
      "ImportedBid"
    ],
    "LoadCartByQuoteNumberResult": [
      "Error",
      "LoadCartByQuoteNumber"
    ],
    "LoadCustomerCartResult": [
      "Error",
      "LoadCustomerCart"
    ],
    "LoadSavedCartResult": [
      "Error",
      "LoadSavedCart"
    ],
    "LoginAsCustomerResult": [
      "AuthenticatedCustomer",
      "Error"
    ],
    "MassAddResult": [
      "Error",
      "MassAdd"
    ],
    "OpenAccountPaymentResult": [
      "Error",
      "OpenAccountPayment"
    ],
    "OpenAccountReceiptResult": [
      "Error",
      "OpenAccountReceipt"
    ],
    "OpenAccountReviewOrderResult": [
      "Error",
      "OpenAccountReviewOrder"
    ],
    "OptionSelection": [
      "RecommendedOptionSelection",
      "RequiredOptionSelection",
      "VariationOptionSelection"
    ],
    "OrderDetailsResult": [
      "NotFoundError",
      "OrderDetails"
    ],
    "OrderLookupResult": [
      "NotFoundError",
      "OrderLookup"
    ],
    "OrderPaymentReprocessResult": [
      "Error",
      "OrderDetails"
    ],
    "OrderResult": [
      "Error",
      "Order"
    ],
    "OrderReturnDetailsResult": [
      "Error",
      "OrderReturnDetails"
    ],
    "OrderTrackingResult": [
      "NotFoundError",
      "OrderTracking"
    ],
    "PasswordResetResult": [
      "AuthenticatedCustomer",
      "Error"
    ],
    "ProductFamilyResult": [
      "NotFoundError",
      "ProductFamily"
    ],
    "ProductMediaAsset": [
      "Image",
      "Video"
    ],
    "ProductSearchResponse": [
      "ProductSearchResult",
      "SearchRedirect"
    ],
    "ProjectCommentResult": [
      "Error",
      "ProjectComment"
    ],
    "ProjectResult": [
      "NotFoundError",
      "Project"
    ],
    "QuickStartShowcaseResultUnion": [
      "NotFoundError",
      "QuickStartShowcaseResult"
    ],
    "QuoteByNumberResult": [
      "Error",
      "Quote"
    ],
    "ReceiptCreateAccountResult": [
      "Error",
      "ReceiptCreateAccount"
    ],
    "RejectDiscountResult": [
      "Error",
      "RejectDiscount"
    ],
    "SaveCartResult": [
      "Error",
      "SaveCart"
    ],
    "SaveCustomProductImageResult": [
      "CustomImage",
      "Error"
    ],
    "SpecialProductResult": [
      "Error",
      "SpecialProduct"
    ],
    "StartAmazonPayCheckoutResult": [
      "Error",
      "StartAmazonPayCheckout"
    ],
    "TrilogiePricingResult": [
      "Error",
      "TrilogiePricing"
    ],
    "UpdateAccountResponse": [
      "AccountUpdated",
      "Error"
    ],
    "UpdateProjectMemberPermissionsResult": [
      "Error",
      "ProjectMember"
    ]
  }
};
      export default result;
    