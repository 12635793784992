import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TextButton } from '../../buttons';
import { SmsIcon } from '../../svg/icons.component';
import { useLiveChat } from '../../../hooks/analytics/live-chat/live-chat.hooks';

const ChatToggle: FunctionComponent<
	PropsWithChildren<{
		className?: string;
		iconClass?: string;
		automationHook?: string;
		onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	}>
> = ({ onClick, className, automationHook, iconClass, children }) => {
	return (
		<TextButton
			onClick={onClick}
			automationHook={automationHook}
			className={`${
				className ??
				'hover-theme-primary theme-secondary f-inherit bw0 pa0 link bg-transparent mr3-ns v-mid omniHomeGreyDark-ns omniHomeF6-ns'
			}`}>
			<div className="flex flex-column flex-row-ns items-center justify-center h-100">
				<SmsIcon className={`${iconClass ?? 'f2 f5-ns mr1-ns'}`} />
				<div>{children}</div>
			</div>
		</TextButton>
	);
};

export type ChatButtonProps = {
	className?: string;
	iconClass?: string;
	automationHook?: string;
};

/**
 * ChatButton - renders a button to initiate a chat.
 *
 * @param {ChatButtonProps} chatButtonProps
 */
export const ChatButton: FunctionComponent<ChatButtonProps> = ({ className, iconClass, automationHook }) => {
	const openLiveChat = useLiveChat();

	return (
		<ChatToggle onClick={openLiveChat} className={className} iconClass={iconClass} automationHook={automationHook}>
			Chat <span className="dn di-ns">With Us</span>
		</ChatToggle>
	);
};
