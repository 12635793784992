import React, { FunctionComponent, useState } from 'react';
import loadable from '@loadable/component';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { useTrackEvent } from '../../../hooks/analytics/analytics.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { FEATURE_FLAGS } from '../../../constants/general';
import { buildGTMHeaderNotificationsClick } from '../../../helpers/analytics/gtm/gtm-utils.helper';
import { ClickableElement } from '../../buttons';
import { Badge } from '../../common-components/badge/badge.component';
import { NotificationsIcon } from '../../svg/icons.component';
import { notificationBellHidden } from './notification-badge.css';

const LoadableNotificationFeed = loadable(
	() => import(/* webpackChunkName: "notification-feed" */ '../notification-feed/notification-feed.component'),
	{
		resolveComponent: ({ NotificationFeed }) => NotificationFeed
	}
);

export const NotificationsBadge: FunctionComponent<{ isHidden: boolean; isBuildDomain?: boolean }> = ({
	isHidden,
	isBuildDomain = true
}) => {
	const { data: customerData } = useCustomer();
	const trackEvent = useTrackEvent();
	const unreadNotificationCount = customerData?.customer?.unreadNotificationCount ?? 0;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const enableProjectNotifications = useFeature<boolean>(FEATURE_FLAGS.PROJECT_NOTIFICATIONS);

	const handleClick = () => {
		setIsOpen(!isOpen);
		trackEvent(buildGTMHeaderNotificationsClick());
	};

	return enableProjectNotifications ? (
		<div className={isHidden ? notificationBellHidden : ''}>
			<ClickableElement ariaLabel="notifications" onClick={handleClick}>
				<Badge count={unreadNotificationCount} isBuildDomain={isBuildDomain}>
					<NotificationsIcon className={`${isBuildDomain ? 'f2 theme-grey' : 'f3 theme-primary'} nr2 `} />
				</Badge>
			</ClickableElement>
			{isOpen && <LoadableNotificationFeed setIsOpen={setIsOpen} />}
		</div>
	) : null;
};
