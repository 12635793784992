// THIS IS A GENERATED FILE. DO NOT MODIFY
import { RouteProps } from 'react-router';
import loadable from '@loadable/component';
import { APPLIANCE_PACKAGE_BUILDER_LINK } from "../constants/links";
import { ARTICLE_PAGE_LINK } from "../constants/links";
import { ACCOUNT_SUBSCRIPTION_LINK } from "../constants/links";
import { CHECKOUT_PAGE_LINK } from "../constants/links";
import { CHECKOUT_RECEIPT_LINK } from "../constants/links";
import { COLLECTIONS_ROUTE } from "../constants/general";
import { ACCOUNT_CREATE_LINK } from "../constants/links";
import { ORDER_TRACK_LINK } from "../constants/links";
import { ORDER_SUMMARY_LINK } from "../constants/links";
import { SET_UP_RETURN_LINK } from "../constants/links";
import { HOMEPAGE_LINK } from "../constants/links";
import { JUST_FOR_YOU } from "../constants/links";
import { ACCOUNT_LOGIN_LINK } from "../constants/links";
import { OPEN_ACCOUNT_RECEIPT_LINK } from "../constants/links";
import { CHECKOUT_OPEN_ACCOUNT_REVIEW } from "../constants/links";
import { ECRM_ORDER_RECEIPT_LINK } from "../constants/links";
import { ORDER_STATUS_LINK } from "../constants/links";
import { ACCOUNT_RESET_PASSWORD } from "../constants/links";
import { PRO_SIGNUP_LINK } from "../constants/links";
import { SEARCH_ROUTE } from "../constants/general";
import { SHOP_BY_LOOK_LINK } from "../constants/links";
import { SHOWROOM_LINK } from "../constants/links";
import { SITE_MAP_LINK } from "../constants/links";
import { SUBMIT_REVIEW_TERMS_LINK } from "../constants/links";
import { CART_LINK } from "../constants/links";
import { ORDER_CART_LINK } from "../constants/links";
import { QUOTE_CART_LINK } from "../constants/links";
import { CATEGORY_ROUTE } from "../constants/general";
import { ADD_CREDIT_CARD } from "../constants/links";
import { BEGIN_CHECKOUT_LINK } from "../constants/links";
import { COMPLEX_ORDER_PROJECT_LINK } from "../constants/links";
import { START_AMAZON_PAY_LINK } from "../constants/links";
import { ACCOUNT_DETAILS_LINK } from "../constants/links";
import { ACCOUNT_ADDRESSES_LINK } from "../constants/links";
import { ACCOUNT_COMPANY_DETAILS_LINK } from "../constants/links";
import { ORDER_INVOICES_BY_DATE_RANGE } from "../constants/links";
import { ORDER_INVOICES_BY_PROJECT } from "../constants/links";
import { DOWNLOAD_ORDERS_LINK } from "../constants/links";
import { ACCOUNT_OPEN_ACCOUNT_LINK } from "../constants/links";
import { ORDER_CANCEL_LINK } from "../constants/links";
import { ORDER_LINK } from "../constants/links";
import { RETURN_LINK } from "../constants/links";
import { ACCOUNT_ORDERS_LINK } from "../constants/links";
import { ACCOUNT_LINK } from "../constants/links";
import { ACCOUNT_RETURNS_LINK } from "../constants/links";
import { ACCOUNT_PAYMENTS_LINK } from "../constants/links";
import { ACCOUNT_CHARGES_LINK } from "../constants/links";
import { RETURN_LABEL_LINK } from "../constants/links";
import { ACCOUNT_SALES_REP_LINK } from "../constants/links";
import { ACCOUNT_SAVED_CARTS_LINK } from "../constants/links";
import { ORDER_RETURN_LINK } from "../constants/links";
import { ACCOUNT_STORE_CREDIT_LINK } from "../constants/links";
import { ACCOUNT_TRADE_CREDIT_LINK } from "../constants/links";
import { INTERNAL_FIND_CUSTOMER_LINK } from "../constants/links";
import { SALES_FORCE_FIND_CUSTOMER_LINK } from "../constants/links";
import { MANUFACTURER_INFO_LINK } from "../constants/links";
import { VENDOR_INFO_LINK } from "../constants/links";
import { PRODUCT_DETAILS_ROUTE } from "../constants/general";
import { PROJECT_ONBOARDING_LINK } from "../constants/links";
import { ACCOUNT_PROJECTS_DETAILS } from "../constants/links";
import { PROJECT_INVITE_LINK } from "../constants/links";
import { PROJECT_PORTFOLIO_LINK } from "../constants/links";
import { ACCOUNT_PROJECTS_LINK } from "../constants/links";
import { PROJECTS_LINK } from "../constants/links";
import { SHOWROOM_APPT_MANAGEMENT_LINK } from "../constants/links";
import { SHOWROOM_LOCATION_ROUTE } from "../constants/showroom";
import { SHOWROOM_SEARCH_LINK } from "../constants/links";
import { SHOWROOM_BOOK_APPT_IFRAME_LINK } from "../constants/links";

export const generatedRoutes: RouteProps & { [key: string]: any }[] = [
{ path: '/account/order/shipments/:orderId', exact: true, DANGEROUSLY_IS_LIVE: true, requiresAccount: true, component: loadable(() => import(/* webpackChunkName: "routedorder" */"../pages/ecrm/ecrm-order-shipments/ecrm-order-shipments.page"), { resolveComponent: ({ RoutedOrder }) => RoutedOrder }) },
{ path: '/account/projects/:quickStartId([0-9]+)/showcase', DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedquickstartproject" */"../pages/quickstart-project/quickstart-project.page"), { resolveComponent: ({ RoutedQuickstartProject }) => RoutedQuickstartProject }) },
{ path: ['/order/receipt/:orderNumber([0-9]+)', ECRM_ORDER_RECEIPT_LINK],    exact: true,
    requiresAccount: false,
    DANGEROUSLY_IS_LIVE: false, component: loadable(() => import(/* webpackChunkName: "routedorderreceipt" */"../pages/order-receipt/order-receipt.page"), { resolveComponent: ({ RoutedOrderReceipt }) => RoutedOrderReceipt }) },
{ path: '/order/shipments/:orderId', exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedorder" */"../pages/order-shipments/order-shipments.page"), { resolveComponent: ({ RoutedOrder }) => RoutedOrder }) },
{ path: ['/product/summary/:familyId([0-9]+)'],    exact: false,
    DANGEROUSLY_IS_LIVE: true,
    ddRum: { rumViewName: 'PLA' }, component: loadable(() => import(/* webpackChunkName: "routedplapage" */"../pages/pla/pla-router/pla-router.page"), { resolveComponent: ({ RoutedPlaPage }) => RoutedPlaPage }) },
{ path: '/internal/construct-testing', exact: false, component: loadable(() => import(/* webpackChunkName: "routedconstructtesting" */"../pages/dev-resources/construct-testing/construct-testing.page"), { resolveComponent: ({ RoutedConstructTesting }) => RoutedConstructTesting }) },
{ path: '/internal/employee', exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routeddeveloperresources" */"../pages/internal/employee/internal-employee.page"), { resolveComponent: ({ RoutedDeveloperResources }) => RoutedDeveloperResources }) },
{ path: '/internal/product-testing', exact: true, requiresEmployeeAuthentication: true, component: loadable(() => import(/* webpackChunkName: "routeddeveloperresources" */"../pages/dev-resources/product-testing/product-testing.page"), { resolveComponent: ({ RoutedDeveloperResources }) => RoutedDeveloperResources }) },
{ path: '/support/:pageName', DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedsupportpage" */"../pages/support/support.page"), { resolveComponent: ({ RoutedSupportPage }) => RoutedSupportPage }) },
{ path: ['/compare'],    exact: true,
    DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedcomparerouter" */"../pages/compare/compare.page"), { resolveComponent: ({ RoutedCompareRouter }) => RoutedCompareRouter }) },
{ path: '/creditcardgateway', layout: 'simple', component: loadable(() => import(/* webpackChunkName: "routedcreditcardgatewaypage" */"../pages/credit-card-gateway/credit-card-gateway.page"), { resolveComponent: ({ RoutedCreditCardGatewayPage }) => RoutedCreditCardGatewayPage }) },
{ path: '/dev-resources', exact: true, requiresEmployeeAuthentication: true, component: loadable(() => import(/* webpackChunkName: "routeddeveloperresources" */"../pages/dev-resources/dev-resources.page"), { resolveComponent: ({ RoutedDeveloperResources }) => RoutedDeveloperResources }) },
{ path: ['/learn', '/learn/room/:room', '/learn/topic/:topic'], exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedlearningcenterpage" */"../pages/learning-center/learning-center.page"), { resolveComponent: ({ RoutedLearningCenterPage }) => RoutedLearningCenterPage }) },
{ path: ACCOUNT_ADDRESSES_LINK, exact: false, requiresAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedaddresses" */"../pages/ecrm/addresses/addresses.page"), { resolveComponent: ({ RoutedAddresses }) => RoutedAddresses }) },
{ path: ACCOUNT_CHARGES_LINK, exact: true, requiresAccount: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedaccountcharges" */"../pages/ecrm/recent-transactions/recent-transactions.page"), { resolveComponent: ({ RoutedAccountCharges }) => RoutedAccountCharges }) },
{ path: ACCOUNT_COMPANY_DETAILS_LINK, exact: true, requiresAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedcompanydetails" */"../pages/ecrm/company-details/company-details.page"), { resolveComponent: ({ RoutedCompanyDetails }) => RoutedCompanyDetails }) },
{ path: ACCOUNT_CREATE_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedcreateaccount" */"../pages/create-account/create-account.page"), { resolveComponent: ({ RoutedCreateAccount }) => RoutedCreateAccount }) },
{ path: ACCOUNT_DETAILS_LINK, exact: true, requiresAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedaccountdetails" */"../pages/ecrm/account-details/account-details.page"), { resolveComponent: ({ RoutedAccountDetails }) => RoutedAccountDetails }) },
{ path: ACCOUNT_LOGIN_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedlogin" */"../pages/login/login.page"), { resolveComponent: ({ RoutedLogin }) => RoutedLogin }) },
{ path: ACCOUNT_OPEN_ACCOUNT_LINK, exact: true, requiresAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedaccountopenaccount" */"../pages/ecrm/open-account/open-account.page"), { resolveComponent: ({ RoutedAccountOpenAccount }) => RoutedAccountOpenAccount }) },
{ path: [ACCOUNT_ORDERS_LINK, ACCOUNT_LINK], exact: true, requiresAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedorderlistpage" */"../pages/ecrm/orders-returns/orders.page"), { resolveComponent: ({ RoutedOrderListPage }) => RoutedOrderListPage }) },
{ path: ACCOUNT_PAYMENTS_LINK, exact: true, requiresAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedpayments" */"../pages/ecrm/payments/payments.page"), { resolveComponent: ({ RoutedPayments }) => RoutedPayments }) },
{ path: ACCOUNT_PROJECTS_DETAILS, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedprojectdetailspage" */"../pages/project/project-details-page/project-details.page"), { resolveComponent: ({ RoutedProjectDetailsPage }) => RoutedProjectDetailsPage }) },
{ path: [ACCOUNT_PROJECTS_LINK, PROJECTS_LINK],    exact: true,
    DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedprojectrouter" */"../pages/project/project-router/project-router.page"), { resolveComponent: ({ RoutedProjectRouter }) => RoutedProjectRouter }) },
{ path: ACCOUNT_RESET_PASSWORD, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedpasswordresetpage" */"../pages/password-reset/password-reset.page"), { resolveComponent: ({ RoutedPasswordResetPage }) => RoutedPasswordResetPage }) },
{ path: ACCOUNT_RETURNS_LINK, exact: true, requiresAccount: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedpayments" */"../pages/ecrm/orders-returns/returns.page"), { resolveComponent: ({ RoutedPayments }) => RoutedPayments }) },
{ path: ACCOUNT_SALES_REP_LINK, exact: true, requiresAccount: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedsalesrep" */"../pages/ecrm/sales-rep/sales-rep.page"), { resolveComponent: ({ RoutedSalesRep }) => RoutedSalesRep }) },
{ path: ACCOUNT_SAVED_CARTS_LINK, requiresAccount: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedsavedcarts" */"../pages/ecrm/saved-carts/saved-carts.page"), { resolveComponent: ({ RoutedSavedCarts }) => RoutedSavedCarts }) },
{ path: ACCOUNT_STORE_CREDIT_LINK, exact: true, requiresAccount: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedstorecredit" */"../pages/ecrm/store-credit/store-credit.page"), { resolveComponent: ({ RoutedStoreCredit }) => RoutedStoreCredit }) },
{ path: ACCOUNT_SUBSCRIPTION_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedcatalogpage" */"../pages/catalog/catalog-subscribe.page"), { resolveComponent: ({ RoutedCatalogPage }) => RoutedCatalogPage }) },
{ path: ACCOUNT_TRADE_CREDIT_LINK, exact: true, requiresAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedtradecredit" */"../pages/ecrm/trade-credit/trade-credit.page"), { resolveComponent: ({ RoutedTradeCredit }) => RoutedTradeCredit }) },
{ path: ADD_CREDIT_CARD, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedaddcardpage" */"../pages/checkout/add-credit-card/add-credit-card.page"), { resolveComponent: ({ RoutedAddCardPage }) => RoutedAddCardPage }) },
{ path: APPLIANCE_PACKAGE_BUILDER_LINK,    exact: true,
    DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedappliancepackagebuilderrouter" */"../pages/appliance-package-builder/appliance-package-builder.page"), { resolveComponent: ({ RoutedAppliancePackageBuilderRouter }) => RoutedAppliancePackageBuilderRouter }) },
{ path: ARTICLE_PAGE_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedarticlepage" */"../pages/article/article.page"), { resolveComponent: ({ RoutedArticlePage }) => RoutedArticlePage }) },
{ path: BEGIN_CHECKOUT_LINK, exact: false, layout: 'minimized', DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedbegincheckoutpage" */"../pages/checkout/begin-checkout/begin-checkout.page"), { resolveComponent: ({ RoutedBeginCheckoutPage }) => RoutedBeginCheckoutPage }) },
{ path: CART_LINK, exact: false, backgroundStyle: 'grey', DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedcart" */"../pages/cart/cart-router/cart-router.page"), { resolveComponent: ({ RoutedCart }) => RoutedCart }) },
{ path: [CATEGORY_ROUTE],    exact: false,
    DANGEROUSLY_IS_LIVE: true,
    ddRum: { rumViewNameSetByChild: true }, component: loadable(() => import(/* webpackChunkName: "routedcategoryrouter" */"../pages/category/category-router/category-router.page"), { resolveComponent: ({ RoutedCategoryRouter }) => RoutedCategoryRouter }) },
{ path: CHECKOUT_OPEN_ACCOUNT_REVIEW, requiresAuthentication: true, exact: false, component: loadable(() => import(/* webpackChunkName: "routedopenaccountreviewpage" */"../pages/open-account-review/open-account-review.page"), { resolveComponent: ({ RoutedOpenAccountReviewPage }) => RoutedOpenAccountReviewPage }) },
{ path: CHECKOUT_PAGE_LINK, exact: false, layout: 'minimized', DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedcheckoutpage" */"../pages/checkout/checkout.page"), { resolveComponent: ({ RoutedCheckoutPage }) => RoutedCheckoutPage }) },
{ path: CHECKOUT_RECEIPT_LINK, exact: true, backgroundStyle: 'grey', DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedcheckoutreceiptpagepage" */"../pages/checkout-receipt/checkout-receipt.page"), { resolveComponent: ({ RoutedCheckoutReceiptPagePage }) => RoutedCheckoutReceiptPagePage }) },
{ path: COLLECTIONS_ROUTE, DANGEROUSLY_IS_LIVE: true, exact: true, component: loadable(() => import(/* webpackChunkName: "routedcollectionspage" */"../pages/collections/collections.page"), { resolveComponent: ({ RoutedCollectionsPage }) => RoutedCollectionsPage }) },
{ path: COMPLEX_ORDER_PROJECT_LINK, exact: true, layout: 'simple', DANGEROUSLY_IS_LIVE: false, component: loadable(() => import(/* webpackChunkName: "routedcomplexorderprojectnamepage" */"../pages/checkout/complex-order-project-iframe/complex-order-project-iframe.page"), { resolveComponent: ({ RoutedComplexOrderProjectNamePage }) => RoutedComplexOrderProjectNamePage }) },
{ path: DOWNLOAD_ORDERS_LINK, exact: true, requiresAccount: false, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedinvoicesrequestform" */"../pages/ecrm/invoices-request-form/invoices-request-form.page"), { resolveComponent: ({ RoutedInvoicesRequestForm }) => RoutedInvoicesRequestForm }) },
{ path: HOMEPAGE_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, ddRum: { rumViewName: 'Home' }, component: loadable(() => import(/* webpackChunkName: "routedhomepage" */"../pages/home/home.page"), { resolveComponent: ({ RoutedHomePage }) => RoutedHomePage }) },
{ path: [INTERNAL_FIND_CUSTOMER_LINK, SALES_FORCE_FIND_CUSTOMER_LINK],    exact: true,
    requiresEmployeeAuthentication: true,
    DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedfindcustomerpage" */"../pages/internal/find-customer/find-customer.page"), { resolveComponent: ({ RoutedFindCustomerPage }) => RoutedFindCustomerPage }) },
{ path: JUST_FOR_YOU, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedjustforyoupage" */"../pages/just-for-you/just-for-you.page"), { resolveComponent: ({ RoutedJustForYouPage }) => RoutedJustForYouPage }) },
{ path: MANUFACTURER_INFO_LINK, exact: true, requiresEmployeeAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedmanufacturerinformationpage" */"../pages/internal/manufacturer-information/manufacturer-information.page"), { resolveComponent: ({ RoutedManufacturerInformationPage }) => RoutedManufacturerInformationPage }) },
{ path: OPEN_ACCOUNT_RECEIPT_LINK, requiresAuthentication: true, exact: false, component: loadable(() => import(/* webpackChunkName: "routedopenaccountreceiptpage" */"../pages/open-account-receipt/open-account-receipt.page"), { resolveComponent: ({ RoutedOpenAccountReceiptPage }) => RoutedOpenAccountReceiptPage }) },
{ path: ORDER_CANCEL_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, requiresAuthentication: true, component: loadable(() => import(/* webpackChunkName: "routedordercancellation" */"../pages/ecrm/order-cancellation/order-cancellation.page"), { resolveComponent: ({ RoutedOrderCancellation }) => RoutedOrderCancellation }) },
{ path: ORDER_CART_LINK, exact: false, component: loadable(() => import(/* webpackChunkName: "routedcart" */"../pages/cart/order-cart/order-cart.page"), { resolveComponent: ({ RoutedCart }) => RoutedCart }) },
{ path: ORDER_INVOICES_BY_DATE_RANGE, exact: true, requiresAccount: false, DANGEROUSLY_IS_LIVE: false, layout: 'simple', component: loadable(() => import(/* webpackChunkName: "routedinvoicesbydaterange" */"../pages/ecrm/invoices/invoices-by-date-range.page"), { resolveComponent: ({ RoutedInvoicesByDateRange }) => RoutedInvoicesByDateRange }) },
{ path: ORDER_INVOICES_BY_PROJECT, exact: true, requiresAccount: false, DANGEROUSLY_IS_LIVE: false, layout: 'simple', component: loadable(() => import(/* webpackChunkName: "routedinvoicesbyproject" */"../pages/ecrm/invoices/invoices-by-project.page"), { resolveComponent: ({ RoutedInvoicesByProject }) => RoutedInvoicesByProject }) },
{ path: ORDER_LINK, exact: true, requiresAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedorderdetails" */"../pages/ecrm/order-details/order-details.page"), { resolveComponent: ({ RoutedOrderDetails }) => RoutedOrderDetails }) },
{ path: ORDER_RETURN_LINK, exact: true, requiresAccount: false, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedreturnorderdetails" */"../pages/ecrm/setup-return/setup-return.page"), { resolveComponent: ({ RoutedReturnOrderDetails }) => RoutedReturnOrderDetails }) },
{ path: ORDER_STATUS_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedorder" */"../pages/order-status/order-status.page"), { resolveComponent: ({ RoutedOrder }) => RoutedOrder }) },
{ path: [ORDER_TRACK_LINK, ORDER_SUMMARY_LINK, SET_UP_RETURN_LINK], exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedguestorderlookup" */"../pages/guest-order-lookup/guest-order-lookup.page"), { resolveComponent: ({ RoutedGuestOrderLookup }) => RoutedGuestOrderLookup }) },
{ path: PRODUCT_DETAILS_ROUTE,    exact: true,
    DANGEROUSLY_IS_LIVE: true,
    ddRum: { rumViewName: 'PDP' }, component: loadable(() => import(/* webpackChunkName: "routedproductrouter" */"../pages/product/product-router/product-router.page"), { resolveComponent: ({ RoutedProductRouter }) => RoutedProductRouter }) },
{ path: [PROJECT_INVITE_LINK],    DANGEROUSLY_IS_LIVE: false, component: loadable(() => import(/* webpackChunkName: "routedprojectinvitepage" */"../pages/project/project-invite/project-invite.page"), { resolveComponent: ({ RoutedProjectInvitePage }) => RoutedProjectInvitePage }) },
{ path: [PROJECT_ONBOARDING_LINK],    exact: true,
    DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedcomparerouter" */"../pages/project/onboarding/project-onboarding.page"), { resolveComponent: ({ RoutedCompareRouter }) => RoutedCompareRouter }) },
{ path: PROJECT_PORTFOLIO_LINK, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedcreateprojectportfoliopage" */"../pages/project/project-portfolio/create-project-portfolio.page"), { resolveComponent: ({ RoutedCreateProjectPortfolioPage }) => RoutedCreateProjectPortfolioPage }) },
{ path: PRO_SIGNUP_LINK, exact: true, requiresAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedpayments" */"../pages/pro-signup/pro-signup.page"), { resolveComponent: ({ RoutedPayments }) => RoutedPayments }) },
{ path: QUOTE_CART_LINK, exact: false, component: loadable(() => import(/* webpackChunkName: "routedcart" */"../pages/cart/quote-cart/quote-cart.page"), { resolveComponent: ({ RoutedCart }) => RoutedCart }) },
{ path: RETURN_LABEL_LINK, exact: true, requiresAccount: false, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedorderdetails" */"../pages/ecrm/return-label/return-label.page"), { resolveComponent: ({ RoutedOrderDetails }) => RoutedOrderDetails }) },
{ path: RETURN_LINK, exact: true, requiresAccount: false, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedreturnorderdetails" */"../pages/ecrm/order-return-details/order-return-details.page"), { resolveComponent: ({ RoutedReturnOrderDetails }) => RoutedReturnOrderDetails }) },
{ path: SEARCH_ROUTE, exact: false, DANGEROUSLY_IS_LIVE: true, ddRum: { rumViewName: 'Search' }, component: loadable(() => import(/* webpackChunkName: "routedsearchpage" */"../pages/search/search.page"), { resolveComponent: ({ RoutedSearchPage }) => RoutedSearchPage }) },
{ path: SHOP_BY_LOOK_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedshopbylookpage" */"../pages/shop-by-look/shop-by-look.page"), { resolveComponent: ({ RoutedShopByLookPage }) => RoutedShopByLookPage }) },
{ path: SHOWROOM_APPT_MANAGEMENT_LINK, exact: true, disableAppUpdate: true, DANGEROUSLY_IS_LIVE: false, component: loadable(() => import(/* webpackChunkName: "routedappointmentmanagementpage" */"../pages/showroom/appointment-management/appointment-management.page"), { resolveComponent: ({ RoutedAppointmentManagementPage }) => RoutedAppointmentManagementPage }) },
{ path: SHOWROOM_BOOK_APPT_IFRAME_LINK, exact: true, layout: 'simple', disableAppUpdate: true, DANGEROUSLY_IS_LIVE: false, component: loadable(() => import(/* webpackChunkName: "routedshowroombookapptiframepage" */"../pages/showroom/showroom-book-appt-iframe/showroom-book-appt-iframe.page"), { resolveComponent: ({ RoutedShowroomBookApptIframePage }) => RoutedShowroomBookApptIframePage }) },
{ path: SHOWROOM_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedshowroom" */"../pages/showroom/showroom.page"), { resolveComponent: ({ RoutedShowroom }) => RoutedShowroom }) },
{ path: SHOWROOM_LOCATION_ROUTE, exact: false, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedshowroomlocation" */"../pages/showroom/locations/showroom-location.page"), { resolveComponent: ({ RoutedShowroomLocation }) => RoutedShowroomLocation }) },
{ path: SHOWROOM_SEARCH_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedshowroomsearch" */"../pages/showroom/search-results/showroom-search-results.page"), { resolveComponent: ({ RoutedShowroomSearch }) => RoutedShowroomSearch }) },
{ path: SITE_MAP_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedsitemap" */"../pages/sitemap/sitemap.page"), { resolveComponent: ({ RoutedSitemap }) => RoutedSitemap }) },
{ path: START_AMAZON_PAY_LINK, exact: false, layout: 'simple', DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedstartamazonpay" */"../pages/checkout/start-amazon-pay-checkout-router/start-amazon-pay-checkout-router.page"), { resolveComponent: ({ RoutedStartAmazonPay }) => RoutedStartAmazonPay }) },
{ path: SUBMIT_REVIEW_TERMS_LINK, exact: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedsubmitreviewtermspage" */"../pages/submit-review-terms/submit-review-terms.page"), { resolveComponent: ({ RoutedSubmitReviewTermsPage }) => RoutedSubmitReviewTermsPage }) },
{ path: VENDOR_INFO_LINK, exact: true, requiresEmployeeAuthentication: true, DANGEROUSLY_IS_LIVE: true, component: loadable(() => import(/* webpackChunkName: "routedvendorinformationpagepage" */"../pages/internal/vendor-information/vendor-information.page"), { resolveComponent: ({ RoutedVendorInformationPagePage }) => RoutedVendorInformationPagePage }) },
{ path: '*', DANGEROUSLY_IS_LIVE: false, component: loadable(() => import(/* webpackChunkName: "routedlandingpage" */"../pages/landing/landing.page"), { resolveComponent: ({ RoutedLandingPage }) => RoutedLandingPage }) }
];
