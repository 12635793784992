import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import {
	AnnouncementFieldsFragment,
	ContentItemFieldsFragment,
	GlobalConstructHeaderQuery
} from '../../../__generated__/graphql-client-types';
import { GLOBAL_HEADER } from '../../../queries/global-header/global-header.queries';
import { AnnouncementBanner } from '../announcement-banner/announcement-banner.component';
import { globalTop } from '../header/header.css';

const globalTopWidthMap = {
	0: 'w-33',
	1: 'w-25',
	2: 'flex-grow-1'
};

export type GlobalContentProps = {
	sections: ContentItemFieldsFragment[];
	announcementSections: AnnouncementFieldsFragment[];
};

export const GlobalContentConstruct: FunctionComponent = () => {
	const { data } = useQuery<GlobalConstructHeaderQuery>(GLOBAL_HEADER, { fetchPolicy: 'cache-first' });
	const sections = data?.globalConstructContent ? data.globalConstructContent.sections : [];
	const announcementSections = data?.globalConstructContent ? data.globalConstructContent.announcementSections : [];

	return (
		<div className="bg-theme-grey-lighter">
			{announcementSections.length > 0 &&
				announcementSections.map((section, index) => {
					return <AnnouncementBanner key={`${index}-${section.id}`} announcement={section} />;
				})}
			<div className="dn flex-ns flex-row mw9 center f6 bg-theme-white">
				{sections.map(({ content, id }, index) => (
					<div
						className={`${globalTop} omniHomeGlobalTop ba b--theme-tertiary ${globalTopWidthMap[index]} tc pv1`}
						key={id}
						// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
						data-testid={`global-header-${index + 1}`}
						dangerouslySetInnerHTML={{
							__html: content
						}}
					/>
				))}
			</div>
		</div>
	);
};
