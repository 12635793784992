/**
 * This module contains utility functions necessary to handle Google Tag Manager
 * specific business logic and/or implementation details. It should not have
 * knowledge of any other analytics vendor implementations, nor should it house
 * any logic which is universal to site analytics (not specific to GTM).
 */
import { filterUndefinedFields } from '@fergdigitalcommerce/fergy-utilities';
import {
	Maybe,
	MessageTypeEnum,
	ProductPricingDiscountTypeEnum,
	ProductSearchInput,
	ProductViewEnum,
	SearchVariantFieldsFragment
} from '../../../__generated__/graphql-client-types';
import { FULFILLMENT_OPTION_ID } from '../../../constants/cart/general';
import { AddProductCartItem, Cart, CartItem, ShippingOption } from '../../../types/cart.types';
import { ProductImpression, ProductSearchResult, SearchProduct } from '../../../types/search.types';
import { hasImage } from '../../cloudinary-helper/cloudinary-helper';
import { FindingMethodSubtype, FindingMethodType, TrackedEvent, TrackedEventCase } from '../event-types';
import { HomeDashboardWidgetsEnum } from '../../../constants/home';
import {
	AllGTMEvents,
	FULFILLMENT_METHOD,
	FULFILLMENT_SPEED,
	GTMCartSummary,
	GTMCartSummaryItem,
	GTMCheckoutStep,
	GTMClickTracking,
	GTMCouponSubmission,
	GTMCustomerCheckoutTypeTracking,
	GTMCustomEvent,
	GTMCustomProductDimensions,
	GTMEventWrapper,
	GTMFindingMethodEvent,
	GTMImpression,
	GTMOrderReturnSearchHistory,
	GTMPaymentMethodClickedTracking,
	GTMProduct,
	GTMProjectInteractionTracking,
	GTMSearchProductClickEvent,
	GTMValidationErrorTracking,
	GTMViewProductEvent,
	GTMViewSearchResultsEvent
} from './gtm-types';

/* type abstractions to match the common results from add, remove, and update mutations */
type CartMutationResultItem = {
	id: number;
	variant: {
		id: number;
	};
	quantity: number;
	price: {
		unitPrice: number;
	};
};

export type CartMutationResultCart = {
	items: CartMutationResultItem[];
};

/**
 * A subset of the fields defined in Analytics.ProductFamily client-type
 */
interface ProductFamily {
	id: number;
	application: string;
	productId: string;
	brandInfo: {
		name: string;
	};
	businessCategory: {
		name: string;
	};
	baseCategory: {
		name: string;
	};
	collection: Maybe<{
		name: string;
	}>;
	title: string;
	type: string;
	hasRecommendedOptions: boolean;
}

/**
 * A subset of the fields defined in Analytics.ProductFamily.Variants type
 */
interface ProductVariant {
	id: number;
	title: string;
	priceInfo: {
		current: number;
		discount: Maybe<{
			type: Maybe<ProductPricingDiscountTypeEnum>;
		}>;
	};
	image: {
		id: string;
	};
	isOutOfStock: boolean;
}

/**
 * Abstraction to report a tracking event to GTM, with an optional callback
 */
export const trackGTMEvent = (gtmData: GTMEventWrapper, onComplete?: () => void): void => {
	if (onComplete && !gtmData.eventCallback) {
		gtmData.eventCallback = onComplete;
	}
	window.gtmDataLayer.push(gtmData);
};

/**
 * Mapping function to convert cart items into the format we want for GTM
 *
 * @param {AddProductCartItem} item - cart item as returned from any given cart mutation
 * @returns {GTMCartSummaryItem}
 */
const mapCartSummaryItem = (item: AddProductCartItem): GTMCartSummaryItem => {
	const {
		variant: { id: variantId },
		quantity,
		price: { unitPrice }
	} = item;
	return { uniqueId: variantId, productQuantity: quantity, unitPrice };
};

/**
 * Helper to generate cart summary data from mutation results
 *
 * @param {CartMutationResultCart} cart - the cart returned from any given cart mutation
 * @returns {GTMCartSummary} - the format we want to send to GTM
 */
export const generateCartSummary = (cart: CartMutationResultCart): GTMCartSummary => {
	return {
		items: cart.items.map(mapCartSummaryItem)
	};
};

const checkIsOnSale = (variant: ProductVariant): boolean => {
	return Boolean(variant.priceInfo.discount && ['CONSUMER_SALE', 'PRO_SALE'].includes(variant.priceInfo.discount.type || ''));
};

/**
 * Maps our domain data to GTM-friendly format for Google events.
 * Example:
 * const gtmProduct = buildGTMProduct(
 *     productFamily,
 *     productVariant,
 *     2,
 *     'SUMMER21'
 * );
 */
export const buildGTMProduct = (product: ProductFamily, variant: ProductVariant, quantity: number, couponCode = '') => {
	const gtmProduct: GTMProduct = {
		id: String(variant.id),
		name: `${product.brandInfo.name} ${product.productId}`,
		category: product.baseCategory.name,
		variant: variant.title,
		brand: product.brandInfo.name,
		price: variant.priceInfo.current,
		quantity,
		[GTMCustomProductDimensions.BUSINESS_CATEGORY]: product.businessCategory.name,
		[GTMCustomProductDimensions.COLLECTION]: product.collection?.name,
		[GTMCustomProductDimensions.PRODUCT_TYPE]: product.type,
		[GTMCustomProductDimensions.PRODUCT_APPLICATION]: product.application,
		[GTMCustomProductDimensions.IS_OUT_OF_STOCK]: variant.isOutOfStock,
		[GTMCustomProductDimensions.HAS_IMAGE]: hasImage(variant.image),
		[GTMCustomProductDimensions.PRODUCT_ID]: product.productId,
		[GTMCustomProductDimensions.IS_ON_SALE]: checkIsOnSale(variant),
		[GTMCustomProductDimensions.HAS_RECOMMENDED_OPTIONS]: product.hasRecommendedOptions
	};

	if (couponCode) {
		gtmProduct.coupon = couponCode;
	}

	return gtmProduct;
};

export const buildGTMCartProduct = (item: CartItem, quantity?: number, shippingOptions?: ShippingOption[] | null): GTMProduct => {
	let fulfillmentSpeed;
	if (item.isLTL) {
		const isWhiteGlove = item.fulfillmentOptions.includes(FULFILLMENT_OPTION_ID.WHITE_GLOVE);
		fulfillmentSpeed = isWhiteGlove ? FULFILLMENT_SPEED.WHITE_GLOVE : FULFILLMENT_SPEED.STANDARD;
	} else {
		const options = shippingOptions || [];
		const shippingOptionName = (
			options.filter((option) => !option.isLTL).find((option) => option.selected)?.name ||
			options[0]?.name ||
			''
		).toLowerCase();

		if (shippingOptionName.includes('two')) {
			fulfillmentSpeed = FULFILLMENT_SPEED.TWO_DAY;
		} else if (shippingOptionName.includes('one')) {
			fulfillmentSpeed = FULFILLMENT_SPEED.ONE_DAY;
		} else {
			fulfillmentSpeed = FULFILLMENT_SPEED.STANDARD;
		}
	}

	return {
		id: String(item.variant.id),
		name: `${item.brandName} ${item.modelNumber}`,
		category: item.baseCategory,
		variant: item.variant.name ? item.variant.name : null,
		brand: item.brandName,
		price: item.price.unitPrice,
		quantity: quantity || item.quantity,
		[GTMCustomProductDimensions.PRODUCT_TYPE]: item.type,
		[GTMCustomProductDimensions.PRODUCT_APPLICATION]: item.application,
		[GTMCustomProductDimensions.IS_OUT_OF_STOCK]: item.stockCount === 0,
		[GTMCustomProductDimensions.HAS_IMAGE]: item.image ? hasImage(item.image) : false,
		[GTMCustomProductDimensions.PRODUCT_ID]: item.modelNumber,
		[GTMCustomProductDimensions.IS_ON_SALE]: item.hasProductSale,
		[GTMCustomProductDimensions.FULFILLMENT_METHOD]: item.isLTL ? FULFILLMENT_METHOD.LTL : FULFILLMENT_METHOD.PARCEL,
		[GTMCustomProductDimensions.FULFILLMENT_SPEED]: fulfillmentSpeed
	};
};

/**
 * Maps search results products to GTM product impressions
 */
export const buildGTMSearchImpressions = (impressions: ProductImpression[], list?: string): GTMImpression[] => {
	return impressions.map((impression) => {
		const { product, position } = impression;
		const { id: uniqueId, brandName, familyId, modelNumber, variants, image, priceInfo } = product;
		const productFinish = variants?.find((variant) => variant?.url?.includes(String(uniqueId)))?.name;
		/**
		 * TODO: Add missing data upon GraphQL Schema update & client query update
		 * - GTMCustomProductDimensions.PRODUCT_TYPE
		 */
		return {
			id: String(familyId),
			name: `${brandName} ${modelNumber}`,
			category: undefined,
			variant: productFinish,
			brand: brandName,
			price: priceInfo.current,
			list,
			position,
			[GTMCustomProductDimensions.BUSINESS_CATEGORY]: undefined,
			[GTMCustomProductDimensions.COLLECTION]: product.collection?.name,
			[GTMCustomProductDimensions.IS_OUT_OF_STOCK]: false,
			[GTMCustomProductDimensions.HAS_IMAGE]: hasImage(image),
			[GTMCustomProductDimensions.PRODUCT_ID]: modelNumber,
			[GTMCustomProductDimensions.IS_ON_SALE]: false
		};
	});
};

export const wrapGTMEvent = <T = AllGTMEvents>(
	eventName: TrackedEvent,
	subType: TrackedEventCase,
	eventObj: T,
	searchTerm?: string,
	recommendationType?: string
): GTMEventWrapper<T> => {
	return {
		event: eventName,
		type: subType,
		...(searchTerm && { 'search-term': searchTerm }),
		...(recommendationType && { 'recommendation-type': recommendationType }),
		ecommerce: eventObj
	};
};

const buildGTMProductDetails = (variant: ProductVariant, productFamily: ProductFamily): GTMProduct => {
	return {
		id: String(variant.id),
		name: `${productFamily.brandInfo.name} ${productFamily.productId}`,
		category: productFamily.baseCategory.name,
		variant: variant.title,
		brand: productFamily.brandInfo.name,
		price: variant.priceInfo.current,
		[GTMCustomProductDimensions.BUSINESS_CATEGORY]: productFamily.businessCategory.name,
		[GTMCustomProductDimensions.COLLECTION]: productFamily.collection?.name || undefined,
		[GTMCustomProductDimensions.PRODUCT_TYPE]: productFamily.type,
		[GTMCustomProductDimensions.PRODUCT_APPLICATION]: productFamily.application,
		[GTMCustomProductDimensions.IS_OUT_OF_STOCK]: variant.isOutOfStock,
		[GTMCustomProductDimensions.HAS_IMAGE]: hasImage(variant.image),
		[GTMCustomProductDimensions.PRODUCT_ID]: productFamily.productId,
		[GTMCustomProductDimensions.IS_ON_SALE]: checkIsOnSale(variant),
		[GTMCustomProductDimensions.HAS_RECOMMENDED_OPTIONS]: productFamily.hasRecommendedOptions
	};
};

export const buildGTMViewProductDetails = (variant: ProductVariant, productFamily: ProductFamily): GTMEventWrapper<GTMViewProductEvent> => {
	return wrapGTMEvent(TrackedEvent.PRODUCT_DETAIL_VIEW, TrackedEventCase.PRODUCT_DETAIL_LOAD, {
		detail: {
			products: [buildGTMProductDetails(variant, productFamily)]
		}
	});
};

export const buildGTMProductClick = (
	product: SearchProduct,
	eventType: TrackedEventCase,
	trackingList: string,
	cardIndex: number,
	variant?: SearchVariantFieldsFragment
): GTMEventWrapper<GTMSearchProductClickEvent> => {
	return wrapGTMEvent(TrackedEvent.PRODUCT_CLICK, eventType, {
		click: {
			actionField: {
				list: trackingList
			},
			products: [
				{
					brand: product.brandName,
					[GTMCustomProductDimensions.COLLECTION]: product.collection?.name,
					[GTMCustomProductDimensions.PRODUCT_ID]: product.modelNumber,
					id: product.id.toString(),
					name: `${product.brandName} ${product.modelNumber}`,
					price: product.priceInfo.current,
					variant: variant?.name,
					position: cardIndex + 1,
					// TODO: add these to schema later (mostly from product family)
					category: undefined,
					[GTMCustomProductDimensions.BUSINESS_CATEGORY]: undefined,
					[GTMCustomProductDimensions.PRODUCT_TYPE]: undefined,
					[GTMCustomProductDimensions.PRODUCT_APPLICATION]: undefined
				}
			]
		}
	});
};

export const buildGTMSelectVariant = (variant: ProductVariant, productFamily: ProductFamily): GTMEventWrapper<GTMViewProductEvent> => {
	return wrapGTMEvent(TrackedEvent.PRODUCT_CLICK, TrackedEventCase.VARIANT_SELECT, {
		detail: {
			products: [buildGTMProductDetails(variant, productFamily)]
		}
	});
};

/**
 * Generate analytics tracking search term
 */
export function generateTrackingSearchTerm(results: ProductSearchResult | undefined, query: string): string {
	return results?.correctedSearchTerm ? `${query}~${results.correctedSearchTerm}~spellcheck` : query;
}

export const buildGTMViewSearchResults = (
	eventType: TrackedEvent,
	useCase: TrackedEventCase,
	gtmImpressions: GTMImpression[],
	searchRequest: ProductSearchInput,
	searchResults?: ProductSearchResult,
	viewType?: ProductViewEnum,
	nonstock = false
): GTMEventWrapper<GTMViewSearchResultsEvent> => {
	return {
		...wrapGTMEvent(
			eventType,
			useCase,
			{
				impressions: gtmImpressions
			},
			generateTrackingSearchTerm(searchResults, searchRequest.query ?? '')
		),
		categoryId: searchRequest.categoryId,
		numberOfResults: searchResults?.count,
		pageNumber: searchRequest.offset + 1,
		resultsPerPage: searchRequest.limit,
		sortBy: searchRequest.sortOption,
		viewType: viewType === 'LIST' ? 'list' : 'tile',
		nonstock
	};
};

export const buildGTMSearch = (
	eventType: TrackedEvent,
	useCase: TrackedEventCase,
	searchRequest: ProductSearchInput,
	searchResults?: ProductSearchResult,
	viewType?: ProductViewEnum,
	internal = false,
	nonstock = false,
	newSearch = false,
	previousQuery?: string,
	searchFlagged = false
): GTMEventWrapper<GTMCustomEvent> => {
	const filters = searchRequest.facetFilter?.map((f) => [f.id, f.rangeBound, f.value].filter((s) => s).join(':')).sort();
	return {
		...wrapGTMEvent(eventType, useCase, null, generateTrackingSearchTerm(searchResults, searchRequest.query ?? '')),
		categoryId: searchRequest.categoryId,
		collectionName: searchRequest.collectionName,
		manufacturer: searchRequest.manufacturer,
		numberOfResults: searchResults?.count,
		pageNumber: searchRequest.offset + 1,
		resultsPerPage: searchRequest.limit,
		sortBy: searchRequest.sortOption,
		viewType: viewType === 'LIST' ? 'list' : 'tile',
		internal,
		filterCount: searchRequest.facetFilter?.length ?? 0,
		filters,
		nonstock,
		newSearch,
		...(newSearch && previousQuery && { 'previous-search-term': previousQuery }),
		blocked: searchFlagged,
		queryStep: searchResults?.queryStep
	};
};

export const buildGTMFindingMethod = (type: FindingMethodType, subType: FindingMethodSubtype): GTMFindingMethodEvent => {
	return {
		event: TrackedEvent.FINDING_METHODS,
		findingMethod: type,
		findingSubType: subType
	};
};

export const buildGTMFindingMethodSAYTTerm = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.SAYT_TERM);
export const buildGTMFindingMethodSAYTProduct = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.SAYT_PRODUCT);
export const buildGTMFindingMethodRecentSearchTerm = () =>
	buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.RECENT_SEARCH_TERM);
export const buildGTMFindingMethodSearchDrop = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.SEARCH_DROP);
export const buildGTMFindingMethodCategoryDrop = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.CATEGORY_DROP);
export const buildGTMFindingMethodCollectionDrop = () =>
	buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.COLLECTION_DROP);
export const buildGTMFindingMethodNonstockDrop = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.NONSTOCK_DROP);
export const buildGTMFindingMethodProductListDrop = () =>
	buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.PRODUCT_LIST);
export const buildGTMFindingMethodDynamicRecommendation = () =>
	buildGTMFindingMethod(FindingMethodType.RECOMMENDATIONS, FindingMethodSubtype.DYNAMIC_RECOMMENDATION);

export const buildGTMSearchRedirect = (searchTerm: string) => {
	return {
		event: TrackedEvent.SEARCH_REDIRECT,
		'search-term': searchTerm
	};
};

export const buildGTMCollectionRedirect = (searchTerm: string) => {
	return {
		event: TrackedEvent.COLLECTION_REDIRECT,
		'search-term': searchTerm
	};
};

export const buildGTMFacetChange = (query: string, facetValue: string, groupName: string, facetAdded: boolean) => {
	return {
		event: TrackedEvent.FACET_INTERACTION,
		facetValue,
		groupName,
		isAdd: facetAdded,
		prefix: query
	};
};

export const buildGTMCouponSubmission = (
	coupon: string,
	error: string,
	status: 'accepted' | 'invalid',
	hasAccount: boolean,
	isPro: boolean,
	cartTotal = 0
): GTMCouponSubmission => {
	return {
		event: TrackedEvent.COUPON_SUBMISSION,
		coupon,
		isLoggedIn: hasAccount,
		isPro,
		cartTotal,
		error,
		status
	};
};

export const buildGTMCheckoutStep = (step: number, cart?: Cart | null, option?: string, page?: string): GTMCheckoutStep => {
	return {
		event: TrackedEvent.CHECKOUT,
		page,
		ecommerce: {
			checkout: {
				sessionCartId: cart?.id,
				actionField: filterUndefinedFields({
					step,
					option
				}),
				products: (cart?.items || []).map((item) => {
					return buildGTMCartProduct(item, undefined, cart?.shippingDetails?.shippingOptions);
				})
			}
		}
	};
};

export const buildGTMClickTracking = (linkName: string): GTMClickTracking => {
	return {
		event: TrackedEvent.GENERIC_CLICK_TRACKING,
		linkName
	};
};

export const buildGTMProjectInteractionTracking = (
	event: TrackedEvent,
	component: string,
	interaction: string
): GTMProjectInteractionTracking => {
	return {
		event,
		component,
		interaction
	};
};

export const buildGTMValidationErrorTracking = (message: string): GTMValidationErrorTracking => {
	return {
		event: TrackedEvent.VALIDATION_ERROR,
		message
	};
};

export const buildGTMLoginFailure = (): GTMCustomEvent => {
	return {
		event: TrackedEvent.USER_LOGIN_FAILURE
	};
};

export const buildGTMOrderReturnHistorySearch = (
	searchType: string | null,
	searchString: string | null,
	sortOrder: string,
	status: string,
	numberOfResults: number
): GTMOrderReturnSearchHistory => {
	return {
		event: TrackedEvent.ORDER_RETURN_HISTORY_SEARCH,
		searchType: searchType || '',
		searchTerm: searchString || '',
		sortOrder,
		status,
		numberOfResults
	};
};

export const buildGTMCustomerCheckoutTypeTracking = (isGuestCheckout: boolean): GTMCustomerCheckoutTypeTracking => {
	return {
		event: TrackedEvent.CUSTOMER_CHECKOUT_TYPE,
		customerCheckoutType: isGuestCheckout ? 'guest checkout' : 'returning user'
	};
};

export const buildGTMPaymentMethodClickedTracking = (type: 'amazon' | 'paypal'): GTMPaymentMethodClickedTracking => {
	return {
		event: TrackedEvent.PAYMENT_METHOD_CLICKED,
		type
	};
};

export const buildGTMReviewsBuySectionLinkClick = (): GTMCustomEvent => {
	return {
		event: TrackedEvent.PRODUCT_PAGE_EVENTS,
		type: TrackedEventCase.REVIEWS_BUY_SECTION_LINK_CLICKED
	};
};

export const buildGTMReviewsInteractionClick = (variationInteraction: string): GTMCustomEvent => {
	return {
		event: TrackedEvent.PRODUCT_PAGE_EVENTS,
		type: TrackedEventCase.REVIEWS_INTERACTION,
		variationInteraction
	};
};

export const buildGTMWriteReviewEcrmOrderPage = (): GTMCustomEvent => {
	return {
		event: TrackedEvent.ECRM_ORDER_EVENT,
		type: TrackedEventCase.WRITE_REVIEW_ORDER_PAGE_SECTION
	};
};

export const buildGTMCollectionLinkClick = (collectionName: string, modelNumber: string): GTMCustomEvent => ({
	event: TrackedEvent.COLLECTION_LINK_CLICKED,
	collectionName,
	modelNumber
});

export const buildProductQuantityChange = (isNested: boolean): GTMCustomEvent => ({
	event: TrackedEvent.PRODUCT_PAGE_EVENTS,
	type: TrackedEventCase.QUANTITY_CHANGE,
	input: isNested ? 'nested-product-quantity' : 'main-quantity'
});

export const buildGTMCartDrawerRecommendations = (recommendationType: string): GTMCustomEvent => ({
	event: TrackedEvent.CART_DRAWER_IMPRESSION,
	type: recommendationType
});

export const buildGTMPromoBannerClick = (priority: string, label: string): GTMCustomEvent => ({
	event: TrackedEvent.PROMO_BANNER_CLICK,
	priority,
	label: label || null
});

export const buildGTMPromoBannerDisplay = (priority?: string): GTMCustomEvent => ({
	event: TrackedEvent.PROMO_BANNER_DISPLAY,
	ecommerce: {
		display: {
			actionField: { priority: priority || '' }
		}
	}
});

export const buildGTMOrderNumberClick = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.ORDER_NUMBER_CLICK
});

export const buildGTMViewAllOrders = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_ALL_ORDERS
});

export const buildGTMViewProject = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_PROJECT
});

export const buildGTMViewAllProjects = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_ALL_PROJECTS
});

export const buildGTMViewCartItem = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_CART_ITEM
});

export const buildGTMViewCart = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_CART
});

export const buildGTMViewShopByLook = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_SHOP_BY_LOOK
});

export const buildGTMWidgetsDisplayed = (displayedWidgets: HomeDashboardWidgetsEnum): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.WIDGETS_DISPLAYED,
	displayedWidgets
});

export const buildGTMNoWidgetsDisplayed = (displayedWidgets: HomeDashboardWidgetsEnum): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD_CONTROL,
	type: TrackedEventCase.NO_WIDGETS_DISPLAYED,
	displayedWidgets
});

export const buildGTMHeaderCategoryLevelOneClick = (name: string) => ({
	event: TrackedEvent.MAIN_NAV_MENU_CLICK,
	type: TrackedEventCase.CATEGORY_LEVEL_ONE,
	name
});

export const buildGTMHeaderCategoryLevelTwoClick = (name: string) => ({
	event: TrackedEvent.MAIN_NAV_MENU_CLICK,
	type: TrackedEventCase.CATEGORY_LEVEL_TWO,
	name
});
export const buildGTMVariationGroup = (title: string, position: string): GTMCustomEvent => ({
	event: TrackedEvent.PRODUCT_DETAIL_VIEW,
	type: TrackedEventCase.PRODUCT_DETAIL_LOAD,
	title,
	position
});

export const buildGTMSelectedVariation = (variationName: string, variantId: number): GTMCustomEvent => ({
	event: TrackedEvent.PRODUCT_CONFIGURATION,
	type: TrackedEventCase.PRODUCT_VARIATION_SELECTED,
	label: variationName,
	variationSelected: variantId
});

export const buildGTMChangeShippingMethod = (shippingSpeed: string, shippingMethod: string): GTMCustomEvent => ({
	event: TrackedEvent.CHANGE_SHIPPING_METHOD,
	shippingSpeed,
	shippingMethod
});

export const buildGTMHeaderProjectsClick = (type?: 'individual-select' | 'view-more' | null): GTMCustomEvent => ({
	event: TrackedEvent.HEADER_PROJECTS_CLICK,
	type: type ? (type === 'individual-select' ? TrackedEventCase.VIEW_PROJECT : TrackedEventCase.VIEW_ALL_PROJECTS) : null
});

export const buildGTMHeaderNoProjectsClick = (): GTMCustomEvent => ({
	event: TrackedEvent.HEADER_NO_PROJECTS_CLICK
});

export const buildGTMHeaderNotificationsClick = (): GTMCustomEvent => ({
	event: TrackedEvent.HEADER_NOTIFICATIONS_CLICK,
	type: TrackedEventCase.OPEN_NOTIFICATION_FEED
});

export const buildGTMEcrmNotificationsClick = (): GTMCustomEvent => ({
	event: TrackedEvent.ECRM_NOTIFICATIONS_CLICK,
	type: TrackedEventCase.OPEN_NOTIFICATION_FEED
});

export const buildGTMNotificationTabClick = (type: 'all' | 'orders' | 'projects' | 'settings'): GTMCustomEvent => {
	const typeMap = {
		all: TrackedEventCase.VIEW_ALL_NOTIFICATIONS,
		orders: TrackedEventCase.VIEW_ORDER_NOTIFICATIONS,
		projects: TrackedEventCase.VIEW_PROJECT_NOTIFICATIONS,
		settings: TrackedEventCase.VIEW_NOTIFICATION_SETTINGS
	};
	return {
		event: TrackedEvent.HEADER_NOTIFICATIONS_CLICK,
		type: typeMap[type]
	};
};

export const buildGTMNotificationClick = (type: MessageTypeEnum | null): GTMCustomEvent => ({
	event: TrackedEvent.NOTIFICATION_CLICK,
	type
});

// SAVE TO PROJECTS MULTI ADD BUILDERS
export const buildGTMOpenSaveToProjectsModal = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.OPEN_MODAL
});

export const buildGTMNewProjectClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.NEW_PROJECT_MULTI_SELECT
});

export const buildGTMNewGroupClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.NEW_GROUP_MULTI_SELECT
});

export const buildGTMSelectGroupClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.SELECT_GROUP
});

export const buildGTMEditProjectQuantity = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.QUANTITY_CHANGE
});

export const buildGTMCompleteSaveClick = (type = TrackedEventCase.COMPLETE_SAVING): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type
});

export const buildGTMCancelClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.CANCEL_CLICK
});

export const buildGTMProjectContainsImportedBid = (projectId: number): GTMCustomEvent => ({
	event: TrackedEvent.PAGE_READY,
	type: TrackedEventCase.PROJECTS_HAS_IMPORTED_BID,
	projectId
});

export const buildGTMSblViewCompleteLook = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.VIEW_COMPLETE_LOOK
});

export const buildGTMSblFavoriteLook = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.FAVORITE_LOOK
});

export const buildGTMSblExpandContainer = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.EXPAND_CONTAINER
});

export const buildGTMSblViewAllLooks = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.VIEW_ALL_LOOKS
});

export const buildGTMSblIsDisplayed = (familyId: number): GTMCustomEvent => ({
	event: TrackedEvent.QUICK_STARTS_PDP,
	type: TrackedEventCase.SBL_IS_DISPLAYED,
	label: familyId
});

export const buildGTMAppliancePackageBuilder = (
	label: string,
	event = TrackedEvent.APPLIANCE_PACKAGE_BUILDER_PROGRESS
): GTMCustomEvent => ({
	event,
	label
});

export const buildGTMVideoModalOpened = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.VIDEO_MODAL_OPENED
});

export const buildGTMAddToCartPdpMediaGalleryReorder = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.ADD_TO_CART_PDP_MEDIA_GALLERY_REORDER
});

export const buildGTMImageSelection = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.IMAGE_SELECTION
});

export const buildGTMMediaGalleryOpened = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.MEDIA_GALLERY_MODAL_OPENED
});

export const buildGTMPackageBuilderAddToCart = (): GTMCustomEvent => ({
	event: TrackedEvent.ADD_TO_CART,
	type: TrackedEventCase.PACKAGE_BUILDER_ADD_TO_CART
});

export const buildGTMReviewsExpanded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.REVIEWS_EXPANDABLE_OPENED
});

export const buildGTMQAExpanded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.QA_EXPANDABLE_OPENED
});

export const buildGTMReviewsLoaded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.REVIEWS_LOADED
});

export const buildGTMQALoaded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.QA_LOADED
});
