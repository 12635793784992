import { logError } from 'fergy-core-react-logging';
import React, { FunctionComponent, PropsWithChildren, createContext } from 'react';
import { EVENTS } from '../../constants/general';
import { AnalyticsHelper, DataLayerAdditions } from '../../helpers/analytics/analytics.helper';
import { AllGTMEvents, GTMEvent, GTMEventWrapper, GTMPageData } from '../../helpers/analytics/gtm/gtm-types';

type EventTracker = (data: GTMEventWrapper<AllGTMEvents>) => Promise<void>;
/**
 * @deprecated - exported for tests. Should not be used outside fo the
 * AnalyticsContextProvider
 *
 * Provides state management for tracking analytics events.
 * Only tracks events after their associated page is tracked.
 */
export class AnalyticsContextState {
	private pageHasBeenTracked: boolean;
	private eventQueue: GTMEvent[];

	constructor(private eventTracker = AnalyticsHelper.track, private pageTracker = AnalyticsHelper.pageView) {
		this.pageHasBeenTracked = false;
		this.eventQueue = [];
	}

	trackEvent = (event: GTMEvent) => {
		if (this.pageHasBeenTracked) {
			this.eventTracker(event).catch(logError);
		} else {
			this.eventQueue.push(event);
		}
	};

	trackPageView = (pageData: GTMPageData, dataLayerAdditions?: DataLayerAdditions) => {
		this.pageTracker(pageData, dataLayerAdditions);
		// track any events that were fired before the page was tracked
		this.pageHasBeenTracked = true;
		this.eventQueue.forEach(this.trackEvent);
		this.eventQueue = [];

		document.dispatchEvent(new Event(EVENTS.COMPLETED_PAGE_TRACK));
	};
}

/**
 * DUMMY_STATE allows unsafe usage of the context. However, we initialize the
 * context at the RouteWithLayout level, so in practice the context will never
 * not be initialized.
 */
const DUMMY_STATE: AnalyticsContextState = {
	trackPageView: () => {},
	trackEvent: () => {}
} as unknown as AnalyticsContextState;

/**
 * Context for tracking analytics events for a page.
 * uses an AnalytisContextState instance to ensure events fire in order
 */
export const AnalyticsContext = createContext<AnalyticsContextState>(DUMMY_STATE);

type AnalyticsContextProviderProps = {
	eventTracker?: EventTracker;
	pageTracker?: () => void;
};
/**
 * Wraps children with the AnalyticsContex Provider.
 * Allows consuming children to fire events and page events.
 * Ensures that events only fire after a page event.
 */
export const AnalyticsContextProvider: FunctionComponent<PropsWithChildren<AnalyticsContextProviderProps>> = ({
	eventTracker,
	pageTracker,
	children
}) => {
	const analyticState = new AnalyticsContextState(eventTracker, pageTracker);
	return <AnalyticsContext.Provider value={analyticState}>{children}</AnalyticsContext.Provider>;
};
