import React, { Component, createContext, FunctionComponent, PropsWithChildren, useContext } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import {
	HOMEPAGE_LINK,
	LEARNING_CENTER_LINK,
	SHOP_ALL_CATEGORIES_LINK,
	SHOP_BY_LOOK_LINK,
	SHOWROOM_LINK,
	SUPPORT_EMAIL_US_LINK
} from '../../../constants/links';
import { AnalyticsEvent, AnalyticsHelper } from '../../../helpers/analytics/analytics.helper';
import { buildGTMHeaderCategoryLevelOneClick, buildGTMHeaderCategoryLevelTwoClick } from '../../../helpers/analytics/gtm/gtm-utils.helper';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { useLiveChat } from '../../../hooks/analytics/live-chat/live-chat.hooks';
import { useContactInfo, useContactMethodStatus, useSiteInfo } from '../../../hooks/apollo/site/site.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { Category as NavCategory } from '../../../types/site.types';
import { ClickableElement, TextButton, UsabillaTextButton } from '../../buttons';
import { Drawer } from '../../common-components/drawer/drawer.component';
import { Expandable } from '../../common-components/expandable/expandable.component';
import { Link } from '../../common-components/link/link.component';
import { Tab, Tabs } from '../../common-components/tabs/tabs.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { BuildWFergusonSvg } from '../../svg/general.component';
import { ChevronRightIcon, MenuIcon } from '../../svg/icons.component';
import { SearchBar } from '../search-bar/search-bar.component';
import { useRouteChange } from '../../../hooks/routing/route-change.hook';
import { AccountSection } from './account-section/account-section.component';
import { buildWithFergusonLogo, hide, menuButton, mobileNavDrawer } from './mobile-nav.css';

// #region Categories and Subs
const subCategoryImageOptions = {
	height: 140,
	width: 140,
	crop: 'fill' as const
};

const navUpdatesSubCategoryImageOptions = {
	height: 64,
	width: 64,
	crop: 'pad' as const
};

export type SubCategoryProps = {
	name: string;
	publicID: string;
	url: string;
	analyticsEvent?: AnalyticsEvent;
};

/**
 * SubCategory - renders markup for a sub-category.
 */
const SubCategory: FunctionComponent<SubCategoryProps> = ({ name, publicID, url, analyticsEvent }) => {
	const isMainNavigationUpdatesActive = useFeature(FEATURE_FLAGS.MAIN_NAVIGATION_UPDATES);
	return (
		<Link
			analyticsEvent={analyticsEvent}
			url={url}
			className={`${isMainNavigationUpdatesActive ? 'w-100 flex justify-between items-center' : 'w-50'} link`}
			color={'grey-darker'}
			underline={isMainNavigationUpdatesActive ? false : true}
			underlineHover={true}>
			<div className={`${isMainNavigationUpdatesActive ? 'flex items-center' : 'center tc pb3'}`}>
				<CloudinaryImage
					description={name}
					publicID={publicID}
					options={isMainNavigationUpdatesActive ? navUpdatesSubCategoryImageOptions : subCategoryImageOptions}
				/>
				<h2 className={`f6 ma0 ${isMainNavigationUpdatesActive ? 'pl2 lh-copy fw4' : 'pt1'}`}>{name}</h2>
			</div>
			{isMainNavigationUpdatesActive && <ChevronRightIcon className="f5" />}
		</Link>
	);
};

export type CategoryProps = {
	category: NavCategory;
};

/**
 * Category - renders markup for a category
 */
const Category: FunctionComponent<CategoryProps> = ({ category }) => {
	const isMainNavigationUpdatesActive = useFeature(FEATURE_FLAGS.MAIN_NAVIGATION_UPDATES);
	return (
		<div className={`${isMainNavigationUpdatesActive ? 'pv1' : 'bb'} b--theme-tertiary w-100`}>
			<Expandable heading={category.name} onExpand={() => AnalyticsHelper.track(buildGTMHeaderCategoryLevelOneClick(category.name))}>
				<div className={`flex flex-wrap ${isMainNavigationUpdatesActive ? 'gr2 mv1' : 'mt3'}`}>
					{!isMainNavigationUpdatesActive && (
						<SubCategory
							analyticsEvent={{ data: buildGTMHeaderCategoryLevelTwoClick(`Shop all ${category.name}`) }}
							publicID={category.image.id}
							name={`Shop All ${category.name}`}
							url={category.url}
						/>
					)}
					{category.subCategories?.map((subCategory) => (
						<SubCategory
							analyticsEvent={{ data: buildGTMHeaderCategoryLevelTwoClick(subCategory.name) }}
							publicID={subCategory.image.id}
							name={subCategory.name}
							url={subCategory.url}
							key={subCategory.id}
						/>
					))}
				</div>
			</Expandable>
		</div>
	);
};
// #endregion

// #region Help Tab
export const HelpTab: FunctionComponent = () => {
	const { phone } = useContactInfo();
	const callCenterOpen = useContactMethodStatus('phone');
	const { setIsNavigationOpen } = useContext(MobileNavContext);
	const initiateLiveChat = useLiveChat();

	const handleChatWithUs = () => {
		setIsNavigationOpen(false);
		initiateLiveChat();
	};

	return (
		<>
			<section className="tc">
				<h2 className="f5">Contact Us</h2>
				<nav aria-label="Contact Us">
					<ul className="list pa0">
						<li>
							<Link underline={false} color={'primary'} url={`tel:${phone.number}`}>
								{phone.number}
								{callCenterOpen && <div className="f7 theme-grey mt1 db">Available Now</div>}
							</Link>
						</li>
						<li className="mt3">
							<>
								<TextButton onClick={handleChatWithUs}>Chat With Us</TextButton>
								<div className="f7 theme-grey mt1 db">Available Now</div>
							</>
						</li>
						<li className="mt3">
							<Link underline={false} color={'primary'} url={SUPPORT_EMAIL_US_LINK}>
								Email Us
							</Link>
						</li>
					</ul>
				</nav>
			</section>
			<section className="tc pt2">
				<h2 className="f5 ma0">Design Help &amp; Inspiration</h2>
				<nav aria-label="Design Help and Inspiration">
					<ul className="list pa0 ma0 pt2">
						<li className="ma0 pa2">
							<Link underline={false} color={'primary'} url={SHOWROOM_LINK}>
								Showrooms
							</Link>
						</li>
						<li className="ma0 pa2">
							<Link underline={false} color={'primary'} url={LEARNING_CENTER_LINK}>
								Learning Center
							</Link>
						</li>
						<li className="ma0 pa2">
							<Link underline={false} color={'primary'} url={SHOP_BY_LOOK_LINK}>
								Shop By Look
							</Link>
						</li>
						<li className="ma0 pa2">
							<UsabillaTextButton>Feedback</UsabillaTextButton>
						</li>
					</ul>
				</nav>
			</section>
		</>
	);
};
// #endregion
export type NavigationUIProps = {
	startTabName?: string;
	closeNavigation: () => void;
};
export const NavigationUI: FunctionComponent<NavigationUIProps> = ({ startTabName, closeNavigation }) => {
	const isMainNavigationUpdatesActive = useFeature(FEATURE_FLAGS.MAIN_NAVIGATION_UPDATES);
	const {
		data: { navigation }
	} = useSiteInfo(isMainNavigationUpdatesActive ? null : 6);

	return (
		<Drawer
			ariaLabel="Site Navigation"
			onClose={closeNavigation}
			className={`${isMainNavigationUpdatesActive ? mobileNavDrawer : ''} pa3 z-4`}>
			{isMainNavigationUpdatesActive ? (
				<>
					<Link url={HOMEPAGE_LINK} className="h-100" color="secondary" ariaLabel="Navigate to home page">
						<BuildWFergusonSvg className={`nt2 nl1 h2 ${buildWithFergusonLogo}`} />
					</Link>
					<nav role="navigation" className="flex flex-column justify-between items-start mt1">
						{navigation.map((category) => (
							<Category category={category} key={category.id} />
						))}
					</nav>
				</>
			) : (
				<Tabs startingTabName={startTabName || 'Shop'} tabControlClasses="w-90 fw7">
					<Tab name="Home" url={HOMEPAGE_LINK} onClick={closeNavigation} />
					<Tab name="Shop">
						<nav role="navigation" className="flex flex-column justify-between items-start mt3">
							<div className="pb2 bb b--theme-tertiary w-100">
								<Link
									url={SHOP_ALL_CATEGORIES_LINK}
									className="b"
									color="black"
									analyticsEvent={{ data: buildGTMHeaderCategoryLevelOneClick('Shop All Departments') }}>
									Shop All Departments
								</Link>
							</div>
							{navigation.map((category) => (
								<Category category={category} key={category.id} />
							))}
						</nav>
					</Tab>
					<Tab name="Account">
						<AccountSection />
					</Tab>
					<Tab name="Help">
						<HelpTab />
					</Tab>
				</Tabs>
			)}
		</Drawer>
	);
};

export type MobileNavProps = {
	startOpen?: boolean; // can be used to trigger the drawer immediately
	startTabName?: string; // which tab should be selected
};

export const MobileNav: FunctionComponent<MobileNavProps> = (props) => (
	<MobileNavProvider isNavigationOpen={props.startOpen || false}>
		<MobileNavComponent {...props} />
	</MobileNavProvider>
);

/**
 * MobileNav - renders markup for the small navigation
 */
export const MobileNavComponent: FunctionComponent<MobileNavProps> = ({ startTabName }) => {
	const { isNavigationOpen, setIsNavigationOpen, isTypeaheadOpen, setIsTypeaheadOpen } = useContext(MobileNavContext);
	function closeNavigation() {
		setIsNavigationOpen(false);
	}

	useRouteChange(() => {
		// make sure menu is closed if the user navigates to another page
		closeNavigation();
	});

	const showNavigation = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
		event.preventDefault();
		event.currentTarget.blur();
		setIsNavigationOpen(true);
	};

	const hideClass = isTypeaheadOpen ? hide : '';

	return (
		<>
			{isNavigationOpen && <NavigationUI startTabName={startTabName} closeNavigation={closeNavigation} />}
			<div className="db dn-ns bg-theme-secondary">
				<div className="flex flex-row items-center justify-between pa1">
					<ClickableElement
						className={`${menuButton} ${hideClass} theme-white pointer tc ba bw1 br2 b--transparent lh-solid pv1 pl1 pr2 mr1 hover-b--theme-grey-lighter active-bg-theme-grey-darker`}
						onClick={showNavigation}
						onKeyDown={handleKeys([' ', 'Enter'], showNavigation)}
						ariaLabel="Site Navigation">
						<MenuIcon className="f2 db" />
					</ClickableElement>
					<div className="w-100">
						<SearchBar onTypeaheadOpenChanged={setIsTypeaheadOpen} />
					</div>
				</div>
			</div>
		</>
	);
};

// #region Mobile Nav Context
export type MobileNavContextState = Readonly<{
	isNavigationOpen: boolean;
	isTypeaheadOpen: boolean;
	setIsNavigationOpen: (isNavigationOpen: boolean) => void;
	setIsTypeaheadOpen: (isTypeaheadOpen: boolean) => void;
}>;

const mobileNavContextInitialState: MobileNavContextState = {
	isNavigationOpen: false,
	isTypeaheadOpen: false,
	setIsNavigationOpen: () => {},
	setIsTypeaheadOpen: () => {}
};

export const MobileNavContext = createContext<MobileNavContextState>(mobileNavContextInitialState);

export class MobileNavProvider extends Component<PropsWithChildren<{ isNavigationOpen: boolean }>, MobileNavContextState> {
	readonly state: MobileNavContextState = {
		isNavigationOpen: Boolean(this.props.isNavigationOpen),
		isTypeaheadOpen: false,
		setIsNavigationOpen: (isNavigationOpen: boolean) => {
			this.setState(() => ({ isNavigationOpen }));
		},
		setIsTypeaheadOpen: (isTypeaheadOpen: boolean) => {
			this.setState(() => ({ isTypeaheadOpen }));
		}
	};

	render() {
		return <MobileNavContext.Provider value={this.state}>{this.props.children}</MobileNavContext.Provider>;
	}
}
// #endregion
