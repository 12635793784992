import { useHistory } from 'react-router-dom';
import { findLiveRoute } from '../../helpers/general-helper/general-helper';

// For when we need to trigger navigation programmatically.
// Will handle our link object and navigate them externally if needed.
export const useNavigation = () => {
	const history = useHistory();
	return (url: string, replace?: boolean) => {
		const isOnlyParamChange = url.startsWith('?');
		const isLiveRoute = findLiveRoute(url);
		if (isOnlyParamChange || isLiveRoute) {
			if (replace) {
				history.replace(url);
			} else {
				history.push(url);
			}
		} else {
			if (replace) {
				window.location.replace(url);
			} else {
				window.location.assign(url);
			}
		}
	};
};
