import { SocialPlatformUrl } from '../../components/social-media/social-media-section.types';
import { FERG_FACEBOOK_LINK, FERG_HOUZZ_LINK, FERG_INSTAGRAM_LINK, FERG_PINTEREST_LINK, FERG_YOUTUBE_LINK } from '../links';

export const SOCIAL_MEDIA_URLS: SocialPlatformUrl[] = [
	{
		name: 'facebook',
		url: FERG_FACEBOOK_LINK
	},
	{
		name: 'houzz',
		url: FERG_HOUZZ_LINK
	},
	{
		name: 'instagram',
		url: FERG_INSTAGRAM_LINK
	},
	{
		name: 'pinterest',
		url: FERG_PINTEREST_LINK
	},
	{
		name: 'youtube',
		url: FERG_YOUTUBE_LINK
	}
];
