import React, { FunctionComponent } from 'react';
import { BaseButton, BaseButtonProps } from '../base-button/base-button.component';
import { BUTTON_STYLE_MAP, ButtonSize, ButtonStyle } from '../button.types';
/**
 * StyledButton takes all props base button does except `className` and narrows
 * the `size` prop to not accept null.  This is to reduce inconsistency.
 */
export type StyledButtonProps = {
	buttonStyle?: Extract<ButtonStyle, 'PRIMARY' | 'SECONDARY' | 'INTERNAL' | 'DANGER'>;
	size?: NonNullable<Extract<ButtonSize, 'DEFAULT' | 'SMALL' | 'LARGE'>>;
	isBusy?: boolean;
	busyText?: string;
} & Omit<BaseButtonProps, 'className' | 'size'>;

export const StyledButton: FunctionComponent<StyledButtonProps> = ({
	buttonStyle = 'PRIMARY',
	isBusy,
	busyText,
	children,
	...remainingProps
}) => (
	<BaseButton disabled={isBusy} className={`fw4 ${BUTTON_STYLE_MAP[buttonStyle]}`} {...remainingProps}>
		{isBusy ? busyText : children}
	</BaseButton>
);
