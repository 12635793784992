import { ItemAvailability } from 'schema-dts';

/**
 * A wide type that encompasses all page types that the PageContainer component can handle
 * Contribute a new "page type" checklist:
 * 1. add new page type to this file. Format: '{NewPage}PageType'
 * 2. add the new page type to PageTypes as a union with the existing types.
 * 3. add a typeguard to this file so other components can determine the page type from the object passed. Format: is{NewPage}
 */
export type PageTypes = ProductPageType | {};

/**
 * ProductPageType contains product data attributes needed by the PageContainer
 * component to construct the proper product open graph and schema.org meta data
 */
export type ProductPageType = {
	brandName: string;
	imagePublicId: string;
	isInStock: boolean;
	modelNumber: string;
	price: number;
	productDescription: string;
	productId: string;
	productName: string;
	productUrl: string;
	ratingCount: number | null;
	ratingValue: number | null;
	itemAvailability: ItemAvailability;
};

/**
 * This type guard allows us to determine if the props are of a ProductPageType
 * and also after calling will properly type the parameter as ProductPageType
 */
export function isProductPage(props: PageTypes): props is ProductPageType {
	return (props as ProductPageType).price !== undefined;
}
