import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { focusFirstFocusable } from '../../../helpers/general-helper/general-helper';
import { CloseIcon } from '../../svg/icons.component';
import { Overlay } from '../overlay/overlay.component';
import { Portal } from '../portal/portal.component';
import { sidebar, sidebarHorizontal, sidebarVertical, sidebarVerticalThreeQuarters } from './drawer.css';

const MOBILE_HEIGHT_MAP = {
	full: `h-100`,
	half: `h-50`,
	quarter: `h-25`
};

const THEME_CLASSES = {
	light: 'bg-theme-white',
	transparentDark: 'bg-theme-black-o60'
};

/**
 * Drawer must have an ariaLabel or a title, or both.
 * If passing both an ariaLabel and title, both will be read by a screen reader.
 */
type WithLabelOrTitle =
	| {
			ariaLabel: string;
			title?: string | JSX.Element;
	  }
	| {
			ariaLabel?: string;
			title: string | JSX.Element;
	  };

export type DrawerProps = {
	className?: string;
	children: React.ReactNode;
	classContainerContent?: string;
	direction?: 'left' | 'right' | 'top' | 'bottom';
	onClose?: () => void;
	size?: keyof typeof MOBILE_HEIGHT_MAP;
	title?: string | JSX.Element;
	footer?: JSX.Element;
	showOverlay?: boolean;
	theme?: keyof typeof THEME_CLASSES;
	customCloseButton?: React.ReactElement;
	titleClasses?: string;
	locationForCustomCloseButton?: 'left' | 'right';
	shouldHideBodyScrollbar?: boolean;
	width?: 'Default' | 'ThreeQuarters';
} & WithLabelOrTitle;

export const Drawer: FunctionComponent<DrawerProps> = ({
	ariaLabel,
	/** z-index set to z-4 to move children above overlay (z-3) */
	className = 'pa3 z-4',
	children,
	classContainerContent = '',
	direction = 'left',
	onClose,
	size = MOBILE_HEIGHT_MAP['FULL'],
	title,
	titleClasses,
	showOverlay = true,
	theme = 'light',
	customCloseButton,
	locationForCustomCloseButton = 'right',
	shouldHideBodyScrollbar = true,
	width = 'Default',
	footer
}) => {
	const drawerRef = useRef<HTMLElement>(null);
	const titleValue = typeof title === 'string' ? title : undefined;
	const drawerName = String(titleValue ?? ariaLabel)
		.replace(/\s+/, '-')
		.toLowerCase();
	const portalChildId = `drawer-portal-${drawerName}`;

	/**
	 * Component state: the component has to know if it is closing so it can set contextual opening and closing styles
	 */
	const [isClosing, setIsClosing] = useState(false);

	/**
	 * When a user closes the drawer, we set its closing state to true to trigger the closing animation
	 */
	const closeDrawer = () => {
		setIsClosing(true);
	};

	const showBodyScrollbar = () => {
		document.body.style.overflow = 'auto';
	};
	const hideBodyScrollbar = () => {
		document.body.style.overflow = 'hidden';
	};

	// Fire only once when the component mount
	useEffect(() => {
		if (shouldHideBodyScrollbar) {
			hideBodyScrollbar();
		}

		return () => {
			showBodyScrollbar();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// delay needed for animaton to start
		setTimeout(() => {
			// In this particular case we need to manually set the focus after the animation
			focusFirstFocusable({ ref: drawerRef });
		}, 25);
	}, []);
	/**
	 * Once the closing animation is complete, set the state to false, remove the drawer children from the popup presenter,
	 * and run the parent onClose if it exists
	 */
	const onAnimationEnd = (): void => {
		if (isClosing) {
			if (onClose) {
				onClose();
				showBodyScrollbar();
			}
		}
	};

	const sidebarVerticalWidth = width === 'Default' ? sidebarVertical : sidebarVerticalThreeQuarters;
	/**
	 * Determine the direction of the drawer and apply the appropriate styles
	 */
	const directionStyle = (): string => {
		switch (direction) {
			case 'left':
				return `top-0 bottom-0 left-0 ${sidebarVerticalWidth} ${!isClosing ? 'slideInLeft' : 'slideOutLeft'}`;
			case 'right':
				return `top-0 bottom-0 right-0 ${sidebarVerticalWidth} ${!isClosing ? 'slideInRight' : 'slideOutRight'}`;
			case 'top':
				return `top-0 left-0 right-0 ${MOBILE_HEIGHT_MAP[size]} ${sidebarHorizontal} ${!isClosing ? 'slideInTop' : 'slideOutTop'}`;
			case 'bottom':
				return `left-0 bottom-0 right-0 ${MOBILE_HEIGHT_MAP[size]} ${sidebarHorizontal} ${
					!isClosing ? 'slideInBottom' : 'slideOutBottom'
				}`;
		}
	};

	const closeButton = customCloseButton ? (
		React.cloneElement(customCloseButton, { onClick: closeDrawer, onKeyPress: closeDrawer, tabIndex: 0 })
	) : (
		<div
			role="button"
			aria-label="close"
			className="absolute right-0 top-0"
			onClick={closeDrawer}
			onKeyPress={closeDrawer}
			tabIndex={0}
			data-testid="drawerCloseButton">
			<CloseIcon className="pa3" />
		</div>
	);

	const themeClass = THEME_CLASSES[theme] ? THEME_CLASSES[theme] : '';
	// custom button might need to be placed outside drawer (compare drawer) so don't set overflow
	const overflowStyle = customCloseButton ? '' : 'overflow-y-auto';

	return (
		<Portal portalChildId={portalChildId} portalRootId="drawer-root">
			{showOverlay && !isClosing && <Overlay style="dark" onClick={closeDrawer} zIndex="4" />}
			<section
				ref={drawerRef}
				className={`fixed ${overflowStyle} ${sidebar} ${directionStyle()} ${themeClass} ${className} dn-p`.trim()}
				aria-label={ariaLabel}
				onAnimationEnd={onAnimationEnd}
				id={`drawer-section-${drawerName}`}
				data-testid="drawerSection">
				{locationForCustomCloseButton === 'left' ? closeButton : null}
				{title && (
					<h1 className={`mt0 ${titleClasses}`} data-testid="drawerTitle">
						{title}
					</h1>
				)}
				<div className={`${classContainerContent}`}>{children}</div>
				{locationForCustomCloseButton === 'right' ? closeButton : null}
				{footer && <>{footer}</>}
			</section>
		</Portal>
	);
};
