import { useMutation, useQuery } from '@apollo/client';
import { logError } from 'fergy-core-react-logging';
import { useState } from 'react';
import {
	SiteViewEnum,
	EmployeeQuery,
	EmployeeQueryVariables,
	SetSiteViewPreferenceMutation,
	SetSiteViewPreferenceMutationVariables,
	ToggleRecordingMutation,
	ToggleRecordingMutationVariables,
	RecordingActionEnum
} from '../../../../client/__generated__/graphql-client-types';
import { showAsEmployee } from '../../../helpers/site-helper/site.helper';
import { EMPLOYEE, SET_SITE_VIEW_PREFERENCE } from '../../../queries/site-info/site-info.queries';
import { EmployeeInfo } from '../../../types/site.types';
import { TOGGLE_RECORDING } from '../../../queries/employee/employee.queries';

export const useEmployee = (): { employee: EmployeeInfo; loading: boolean } => {
	const { data, loading } = useQuery<EmployeeQuery, EmployeeQueryVariables>(EMPLOYEE, {
		fetchPolicy: 'cache-first' // employee data will only ever change after redirect to our sso provider, so only fetch once
	});

	if (!data?.employee || loading) {
		return { employee: { id: 0, isAuthenticated: false, fullName: '', email: '', siteViewPreference: 'Consumer' }, loading };
	}
	return { employee: data.employee, loading };
};

export const useSetSiteViewPreference = () => {
	const [setSiteViewPreference] = useMutation<SetSiteViewPreferenceMutation, SetSiteViewPreferenceMutationVariables>(
		SET_SITE_VIEW_PREFERENCE
	);

	return (preference: SiteViewEnum) => {
		return setSiteViewPreference({ variables: { preference } }).catch(logError);
	};
};

export type SiteViewPreference = {
	showAsEmployee: boolean;
	employee: EmployeeInfo;
};

export const useSiteViewPreference = (): SiteViewPreference => {
	const { employee } = useEmployee();
	return {
		showAsEmployee: showAsEmployee(employee),
		employee
	};
};

export const useToggleRecording = () => {
	const [toggleRecording] = useMutation<ToggleRecordingMutation, ToggleRecordingMutationVariables>(TOGGLE_RECORDING);
	const { showAsEmployee: isInternal } = useSiteViewPreference();
	const [loading, setLoading] = useState(isInternal);

	return {
		toggleRecording: (action: RecordingActionEnum) => {
			if (isInternal) {
				toggleRecording({
					variables: { input: { action } }
				})
					.catch(logError)
					.finally(() => setLoading(false));
			}
		},
		loading
	};
};
