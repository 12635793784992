/**
 * returns a keyboard event handler that will call the provided callback if the key triggers the event
 *
 * @param {string[]} keys - array of keys to match
 * @param {() => void} callback - callback to call if key matches the key event
 * @returns {React.KeyboardEventHandler} - handler that calls the callback
 */
export function handleKeys<T = Element>(keys: string[], callback: (event: React.KeyboardEvent<T>) => void): React.KeyboardEventHandler<T> {
	return (event: React.KeyboardEvent<T>) => {
		if (keys.some((key) => key === event.key)) {
			callback(event);
		}
	};
}

/**
 * Typeguard to differentiate between a keyboard event and a mouse event.
 * Example use case: the same callback function is used to handle button activation through mouse click and enter key press.
 */
export function isKeyboardEvent(event: React.KeyboardEvent | React.MouseEvent): event is React.KeyboardEvent {
	return 'key' in event;
}
