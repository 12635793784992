import { logError } from 'fergy-core-react-logging';
import React, { FunctionComponent, MouseEventHandler } from 'react';
import { AnalyticsEvent, AnalyticsHelper } from '../../../helpers/analytics/analytics.helper';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { BUTTON_SIZE_MAP, ButtonSize, ButtonStyle } from '../button.types';

export type BaseButtonProps = {
	// Analytics data that will sent upon clicking the Link.
	analyticsEvent?: AnalyticsEvent;
	buttonStyle?: ButtonStyle;
	ariaLabel?: string;
	automationHook?: string;
	analyticsHook?: string;
	buttonType?: 'button' | 'reset' | 'submit';
	children?: string | JSX.Element | string[];
	className?: string;
	id?: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	size?: ButtonSize;
	disabled?: boolean;
	tabIndex?: 0 | -1;
	testId?: string;
	title?: string;
	useBorderRadius?: boolean;
};

/**
 * Basic Component that all other buttons should compose.
 *
 * @abstract
 */
export const BaseButton: FunctionComponent<BaseButtonProps> = ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	analyticsEvent,
	ariaLabel,
	automationHook,
	analyticsHook,
	buttonType = 'button',
	children,
	className,
	id,
	onClick,
	size = 'DEFAULT',
	disabled,
	testId,
	tabIndex,
	title,
	useBorderRadius = true
}) => {
	const clickHandler = analyticsEvent
		? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				AnalyticsHelper.track(analyticsEvent.data)
					.catch(logError)
					.finally(() => {
						if (onClick) {
							onClick(e);
						}
					});
		  }
		: onClick;
	return (
		<button
			className={`${disabled ? 'not-allowed o-50' : 'pointer'} ba ${
				size === 'CIRCLE' || !useBorderRadius ? '' : 'br2'
			} w-inherit ${className} ${size ? BUTTON_SIZE_MAP[size] : ''}`}
			id={id}
			onClick={clickHandler}
			aria-label={ariaLabel}
			type={buttonType}
			disabled={disabled}
			tabIndex={tabIndex}
			title={title}
			data-automation={generateDataSelector('button', automationHook)}
			data-analytics={generateDataSelector('button', analyticsHook)}
			data-testid={testId}>
			{children}
		</button>
	);
};
