import React, { FunctionComponent, useEffect, useState } from 'react';
import { useCookies } from '@fergdigitalcommerce/fergy-react-components';
import { FEATURE_FLAGS } from '../../../constants/general';
import { useFeature } from '../../../hooks/features/features.hooks';
import { ClickableElement } from '../../buttons';
import { CloseIcon } from '../../svg/icons.component';
import { TEN_YEAR_EXPIRATION } from '../../../constants/cookies';
import { AnnouncementFieldsFragment } from '../../../__generated__/graphql-client-types';

export const BANNER_SEVERITY_MAP = {
	LOW: `bg-theme-primary-lighter b--theme-primary`,
	MEDIUM: `bg-theme-warning-light b--theme-warning`,
	HIGH: `bg-theme-error-light b--theme-error`
};

export type AnnouncementBannerProps = {
	announcement: AnnouncementFieldsFragment;
};

export const AnnouncementBanner: FunctionComponent<AnnouncementBannerProps> = ({ announcement }) => {
	const { message, severity, id } = announcement;
	const { getCookie, setCookie } = useCookies();
	const getCookieName = (cookieId: string) => `hide-announcement-banner-${cookieId}`;
	const isAnnouncementBannerActive = useFeature<boolean>(FEATURE_FLAGS.ANNOUNCEMENT_BANNER);
	const isBannerHidden = getCookie(getCookieName(id)) === 'true';
	const isBannerEnabled = isAnnouncementBannerActive && !isBannerHidden;

	const [showAnnouncementBanner, setShowAnnouncementBanner] = useState(isBannerEnabled);

	const hideBanner = () => {
		setShowAnnouncementBanner(false);
		setCookie(getCookieName(id), 'true', TEN_YEAR_EXPIRATION);
	};

	useEffect(() => {
		setShowAnnouncementBanner(isBannerEnabled);
	}, [isBannerEnabled]);

	return (
		<>
			{showAnnouncementBanner && (
				<div
					className={`bb ${BANNER_SEVERITY_MAP[severity]} f6 tc theme-white relative lh-copy pv1`}
					aria-label={`Site-wide Announcement`}
					data-testid="announcement-banner">
					<div
						className="tc f6 theme-black b"
						dangerouslySetInnerHTML={{
							__html: message
						}}
					/>
					<ClickableElement
						ariaLabel="hide announcement banner"
						className="pointer lh-solid theme-white absolute right-0 top-0 mt1 pt1 pr1"
						onClick={hideBanner}
						onKeyDown={hideBanner}>
						<CloseIcon className={`f5 pa0`} />
					</ClickableElement>
				</div>
			)}
		</>
	);
};
