import { useApolloClient, Cache } from '@apollo/client';
import { useEffect } from 'react';

const pendingEvictions = new Set<string>();

/**
 * useCacheTTL - a hook that can be used to evict Apollo cache entries after a specified amount of time
 *
 * @param ttl - time in milliseconds to evict the item from cache
 * @param options - the Apollo cache evict options https://www.apollographql.com/docs/react/api/cache/InMemoryCache/#evict
 */
export const useCacheTTL = (ttl: number, options: Cache.EvictOptions) => {
	const client = useApolloClient();
	const cache = client.cache;

	useEffect(() => {
		if (ttl > 0) {
			const evictKey = JSON.stringify(Object.values(options));
			if (!pendingEvictions.has(evictKey)) {
				pendingEvictions.add(evictKey);
				setTimeout(() => {
					pendingEvictions.delete(evictKey);
					cache.evict(options);
				}, ttl);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
