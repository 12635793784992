import { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { doesWindowExist, setupFocusTrap } from '../../../helpers/general-helper/general-helper';

export type PortalProps = {
	portalChildId: string;
	portalRootId: string;
	// whether or not we restore focus after the portal is unmounted
	restoreFocus?: boolean;
};

/**
 * The Portal component can be used to render content outside the DOM hierarchy of the parent component.  Portal will handle creating the
 * DOM container if it doesn't exist, and handle cleanup on unmount.
 *
 * Note: React portals do not support SSR.  This component will throw an error if it's rendered on the server.
 * In order to keep the SSR and client rendered content identical, render the Portal after your component mounts.
 * This avoids potential hydrate issues:
 *
 * @see https://reactjs.org/docs/react-dom.html#hydrate
 */
export const Portal: FunctionComponent<PropsWithChildren<PortalProps>> = ({
	portalChildId,
	portalRootId,
	children,
	restoreFocus = true
}) => {
	if (!doesWindowExist()) {
		throw Error('Portal does not support SSR, ensure your component renders same output on client and server');
	}

	const portalChild = document.createElement('div');
	portalChild.id = portalChildId;
	const portalChildRef = useRef(portalChild);
	const focusTrapRef = useRef(() => {});

	// manages the contents of the portal
	useEffect(() => {
		let portalRoot = document.getElementById(portalRootId);
		if (!portalRoot) {
			portalRoot = document.createElement('div');
			portalRoot.id = portalRootId;
			document.body.appendChild(portalRoot);
		}
		portalRoot.appendChild(portalChildRef.current);

		focusTrapRef.current();
		focusTrapRef.current = setupFocusTrap({ selector: `#${portalRootId}`, restoreFocus });

		const cleanupChildRef = portalChildRef;

		return () => {
			focusTrapRef.current();
			cleanupChildRef.current.remove();
		};
	}, [portalRootId, restoreFocus]);

	return createPortal(children, portalChildRef.current);
};
