import React, { FunctionComponent, useContext } from 'react';
import { COMPARE_LINK } from '../../../constants/links';
import { CompareContext } from '../../../contexts/compare/compare.context';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { StyledButton } from '../../buttons';
import { Link } from '../../common-components/link/link.component';
import { ChevronLeftIcon } from '../../svg/icons.component';

export const CompareDrawerButton: FunctionComponent = () => {
	const { variantIds, isOpen, setIsOpen, isUserOnComparePage } = useContext(CompareContext);
	const openDrawer = () => {
		setIsOpen(true);
	};

	return !isOpen && !isUserOnComparePage && variantIds.length > 0 ? (
		<div className="fixed bottom-1 left-1 dib pa2 br2 bg-theme-black-o70 dn-p z-2">
			<div className="flex flex-row items-center">
				<div
					aria-label="open drawer"
					role="button"
					className="theme-white dib pointer pa1 mr1 f4"
					onClick={openDrawer}
					onKeyDown={handleKeys(['Enter', ' '], openDrawer)}
					tabIndex={0}>
					<ChevronLeftIcon className="v-mid" />
				</div>
				<Link url={COMPARE_LINK}>
					<StyledButton size="SMALL">{`Compare ${variantIds.length} Items`}</StyledButton>
				</Link>
			</div>
		</div>
	) : null;
};
